import React, { useEffect, useState } from "react"
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import Header from './HomepageElements/Header'
import Table from "./HomepageElements/Table"
import Testimonial from "./HomepageElements/Testimonial"
import Appss from "./HomepageElements/Appss"
import Features from "./HomepageElements/Features"
import NeedHelp from "./HomepageElements/NeedHelp"
import MarketNews from "./HomepageElements/MarketNews"
import Banners from "./HomepageElements/Banners"
import Blogs from "./HomepageElements/Blogs"
import Trade from "./HomepageElements/Trade"
import Footer from '../Common/Footer'
import Nav from "../Common/Nav"


const Index = () => {

    const [state, setState] = useState([]);

    useEffect(() => {
        getData()       
       }, [])    
        
       const getData=()=>{
        let lang = localStorage.getItem("lang");
        const config = {
            headers: {
                lang:lang,
             // Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          console.log(config);
        axios.get(baseUrl+"/api/get-blog",config).then((res)=>{
          if(res.data.status){
            setState(res.data.data)
          }          
        }).catch((err)=>{
    
        })
       }

    return (
        <div>
           
            <Nav />
            <Header />
            <Banners />
            <Table />
            <Blogs stocksName={state}/>
            <Testimonial />
            <NeedHelp />
            <Features />
            <Appss />
            <MarketNews /> 
            <Footer />
        </div>
    )
}

export default Index;
