import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SellBox({
  firstCoinNew,
  firstCoin,
  firstCoinBalance,
  removeTrailingZeros,
  setStopChanges,
  setOrderMethod,
  limit,
  market,
  sell_commission,
  removeTrailingZerosT,
  handleSellSubmit,
  stopLimit,
  stopPriceSell,
  handleSellChange,
  sellFeeAmyt,
  secondCoin,
  orderMethod,
  disabledButton,
  handleKeyPress,
  sell_order_id,
  sellPrice,
  handleSellPercent,
  sPBtn,
  currentPrice,
  sellAmount,
  sell_order_total,
  type,
  token,
  disabledSellButtn,
}) {
  const { t } = useTranslation();
  const AuthActionSell = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_sell"
          disabled={disabledSellButtn}
        >
          {t("Sell")}
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border3 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">{t("sign_up")}</Link> {t("or")}{" "}
            <Link to="/login-to-buy-sell-crypto">{t("login")}</Link>
          </div>
        </div>
      );
    }
  };

  const sellBoxHtml = () => {
    return (
      <div className="sell-btc-outer buy-sell-outer border1">
        <div className="orderforms-hd">
          <div>
            <label className="ng-binding">
              {t("Sell")} {firstCoinNew != "" ? firstCoinNew : firstCoin}
            </label>
            <span className="f-fr ng-binding">
              {firstCoin} :{" "}
              {firstCoinBalance
                ? Number.isInteger(firstCoinBalance)
                  ? firstCoinBalance
                  : removeTrailingZeros(parseFloat(firstCoinBalance).toFixed(8))
                : 0}{" "}
            </span>
          </div>
        </div>
        <ul className="type-list-outer d-flex justify-content-between">
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "limit");
                setOrderMethod("limit");
              }}
              href="#!"
              className={limit === true ? "active" : ""}
            >
              {t("Limit")}
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "market");
                setOrderMethod("market");
              }}
              href="#!"
              className={market === true ? "active" : ""}
            >
              {t("Market")}
            </a>
          </li>
          {/* <li>
              <a
                onClick={() => this.setStopChanges("stop")}
                href="javascript:voin(0)"
                className={stopLimit == true ? "active" : ""}
              >
                Stop Limit
              </a>
            </li>  */}
        </ul>
        <form
          name="buyForm"
          onSubmit={handleSellSubmit}
          autoComplete="off"
          className="ng-pristine ng-invalid ng-invalid-required"
        >
          <div className="orderforms-inputs  ">
            {stopLimit === true && (
              <div className="field f-cb stop-limit">
                <label className="ng-binding">Stop Limit: </label>
                <div className="iptwrap leftBig limit">
                  <input
                    type="number"
                    step="any"
                    value={stopPriceSell}
                    onChange={handleSellChange}
                    name="stopPriceSell"
                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                  />

                  <span className="unit ng-binding">{secondCoin}</span>
                </div>
                <div className="cls"></div>
              </div>
            )}
            <div className="field f-cb">
              <label className="ng-binding">{t("Price")}: </label>
              <div className="iptwrap leftBig">
                {orderMethod === "market" ? (
                  <>
                    <input
                      type="text"
                      step="any"
                      id="price"
                      value="Market"
                      name="sellPrice"
                      onChange={handleSellChange}
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="sell_order_id"
                      value={sell_order_id}
                      name="sell_order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                ) : (
                  <>
                    <input
                      type={type}
                      step="any"
                      id="price_sell"
                      value={disabledButton === true ? currentPrice : sellPrice}
                      name="sellPrice"
                      onChange={handleSellChange}
                      disabled={disabledButton}
                      onKeyPress={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="sell_order_id"
                      value={sell_order_id}
                      name="sell_order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                )}
              </div>
              <div className="cls"></div>
            </div>
            <div className="field f-cb">
              <label className="ng-binding">{t("Amount")}: </label>
              <div className="iptwrap">
                <input
                  type="number"
                  step="any"
                  name="sellAmount"
                  id="amount_sell"
                  onChange={handleSellChange}
                  value={sellAmount}
                  onKeyPress={handleKeyPress}
                />

                <span className="unit ng-binding">{firstCoin}</span>
              </div>
              <div className="cls"></div>
            </div>
            <div className="field percent f-cb">
              <div className="field4">
                <span
                  className={`col ${sPBtn == 25 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(25)}
                >
                  25%
                </span>
                <span
                  className={`col ${sPBtn == 50 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(50)}
                >
                  50%
                </span>
                <span
                  className={`col ${sPBtn == 75 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(75)}
                >
                  75%
                </span>
                <span
                  className={`col ${sPBtn == 100 ? "my_color_sell" : ""}`}
                  onClick={() => handleSellPercent(100)}
                >
                  100%
                </span>
              </div>
            </div>
            <div className="field f-cb">
              {orderMethod === "market" ? (
                ""
              ) : (
                <>
                  <label className="ng-binding">{t("Total")}: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_sell"
                      name="sell_order_total"
                      value={sell_order_total}
                      onChange={handleSellChange}
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                    />
                    <span className="unit ng-binding">{secondCoin}</span>
                  </div>
                </>
              )}

              <div className="cls"></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  {t("Trade Fee")} :{" "}
                  {sell_commission == null
                    ? 0
                    : Number.isInteger(sell_commission)
                      ? sell_commission
                      : removeTrailingZerosT(
                        parseFloat(sell_commission).toFixed(4)
                      )}{" "}
                  {sell_commission != null && <span>%</span>}
                </p>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  {t("Net Amount")} :{" "}
                  {sellFeeAmyt && sellFeeAmyt != 'NaN' ? parseFloat(sellFeeAmyt).toFixed(8) : 0}
                </p>
              </div>
            </div>
            {AuthActionSell()}
          </div>
        </form>
      </div>
    );
  };
  return <div>{sellBoxHtml()}</div>;
}

export default SellBox;
