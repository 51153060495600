import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { ThirdPartyApi } from './ThirdPartyApis'
import * as myConstList from "../Common/BaseUrl";
import { UpiIdApi } from './Apis';
const baseUrl = myConstList.baseUrl;

function Upi(props) {
    const [upiId, setUpiId] = useState("")
    const [upiIdError, setUpiIdError] = useState("")
    const [upiIdStatus, setUpiIdStatus] = useState("0")
    const [btnDisable, setBtnDisable] = useState(false)



    useEffect(() => {
        if (props.userinfo.length > 0) {
            setUpiId(props.userinfo[0].upi)
            props.userinfo[0].upi_status && setUpiIdStatus( props.userinfo[0].upi_status)
        }
    }, [props])



    const handleChange = (e) => {
        let value = e.target.value
        let name = e.target.name
        // let regex=/^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/
        // let regex = new RegExp(/^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/);
        // let regex = "[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}"
        let regex=/^[\w.-]+@[\w.-]+$/
     
        setUpiId(e.target.value)
        if (!value) {
            setUpiIdError("This field is required")
            return false
        }
        if (value.replace(/\s+/g, '').length == 0) {
            setUpiIdError("Please enter a valid upi id1")
            return false
        }
        // console.log("regexregexregex",value.match("[A-Z]{2}\.?\d{6}\.\d{4}"))
        // if(!value.match(regex)){
        //     setUpiIdError("Please enter a valid upi id")
        //     return false
        // }
        if(!(regex.test(value))){
            setUpiIdError("Please enter a valid upi id")
            return false
        }
        setUpiIdError("")

    }

    const handleSubmit = async() => {
        if (upiIdError) {
            return false
        }
        if (!upiId) {
            setUpiIdError("This field is required")
            return false
        }
        if (upiId.replace(/\s+/g, '').length == 0) {
            setUpiIdError("Please enter a valid UPI Id")
            return false
        }
        setTimeout(() => {
            setBtnDisable(true)
        }, 50);
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let upi_id = {
            upiNumber: upiId,
        };
        let result = await UpiIdApi(upi_id, config)
        setBtnDisable(false)
        if (result.status) {
            props.resendResult(true)
            toast.success("Upi Id updated successfully")
        }
        else {
            props.resendResult(true)
            toast.error(result.message)
        }
    }

return (
    <>
        <div className='upi-outer'>
            <div className='row'>
                <div className='col-md-3 col-12'>
                </div>
                <div className='col-md-9 col-12'>
                    <div className="white_box">
                        <h6 className="position-relative">
                            UPI Verification
                        </h6>
                        <b className="mt-0">Requirements</b>
                        <ul className="dit_ul mb-4">
                            <li>
                                Please Verify Your Upi Number
                            </li>
                        </ul>
                        <div className="row">
                            <div className="form-group col-lg-4 col-md-6">
                                <label>Upi Id </label>
                                {console.log("upiIdStatus",upiIdStatus)}
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter UPI ID"
                                    name="upi"
                                    value={upiId}
                                    onChange={handleChange}
                                    disabled={upiIdStatus !== "0" && upiIdStatus !== "3"?true:false}
                                />
                                {
                                    upiIdError &&
                                    <div className='text-danger'>{upiIdError}</div>
                                }
                               {upiIdStatus == "1"?"": upiIdStatus !== "2" &&
                                    <div className='save-btn'>
                                        <button
                                            className="btn btn_man mt-3"
                                            disabled={btnDisable || upiIdStatus == "1"}
                                            onClick={handleSubmit}
                                        >
                                           {upiIdStatus == "1"? "Submited":"Submit"}
                                        </button>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default Upi