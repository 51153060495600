import React from "react";
import BigNumber from "bignumber.js";

const TickerTable = (value) => (

    <>
        {(value.coin_first_name) ?
            <>

                <td>{isNaN(value.c) ? "0.00":new BigNumber(value.c).toFormat(null, 1)}</td>
                <td>{isNaN(value.p)?"0.00":new BigNumber(value.p).toFormat(null, 1)}</td>
                <td>{isNaN(value.h)?"0.00": new BigNumber(value.h).toFormat(null, 1)}</td>
                <td>{isNaN(value.l)?"0.00": new BigNumber(value.l).toFormat(null, 1)}</td>
                <td>{!isNaN(value.q) ? `${new BigNumber(value.q).toFormat(2, 1)}` : `0.00`}</td>
            </>
            : ""
        }
    </> 

);

export default TickerTable;
