import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import i18n from '../../i18n';
const ChangeLang = () => {
  const { t } = useTranslation();

  const [state, setState] = useState({ lang: "en" })
  //   state = {
  //     lang: "en"
  //   };

  useEffect(() => {
    //localStorage.setItem("lang889", "r46655");
    // console.log(" local=> ",localStorage.getItem("lang889")) 
    getIp()
    // const lang_code = state.lang;
    // i18n.changeLanguage(lang_code);
    // localStorage.setItem("lang", lang_code);
    // const lang = localStorage.getItem("lang");

  }, [])

  const LANGUAGES = [
    { label: "English", code: "en" },
    { label: "korean", code: "ko" },
    { label: "Russian", code: "rus" },
    { label: "Persian", code: "per" },
    { label: "Arabic", code: "ar" }
  ];

  const language_temp = [
    { label: "English", code: "en", lang: "rus" },
    { label: "korean", code: "ko", lang: "rus" },
    { label: "Russian", code: "rus", lang: "rus" },
    { label: "Russian", code: "ru", lang: "rus" },
    { label: "Persian", code: "per", lang: "rus" },
    { label: "Arabic", code: "ar", lang: "rus" }
  ];


  const getIp = () => {
    if (!localStorage.getItem("lang")) {
      axios.get("https://ipinfo.io").then((res) => {
        const resp = res.data;
        // console.log("resp => ", resp.country)
        let obj = language_temp.find(o => o.code == resp.country.toLowerCase());

        // console.log(localStorage.getItem("lang")," ",obj);

        if (obj) {
          localStorage.setItem("lang", obj.lang);
          //i18n.changeLanguage(obj.lang);
          onChangeLang({ target: { value: obj.lang } })
        } else {
          onChangeLang({ target: { value: "en" } })
        }
      }).catch((e) => {
        console.log(e);
        onChangeLang({ target: { value: "en" } })
      })
    }
  };



  const onChangeLang = (e) => {
    // console.log(e);
    //setState({ [e.target.name]: e.target.value })
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(e.target.value);
    if (e.target.value == "ar" || e.target.value == "per") {
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    } else {
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    }
    window.location.reload();

  };

  // const { t } = this.props;
  // const { lang } = this.state;
  //i18n.language
  return (
    <>

      <Form.Control as="select" aria-label="Default select example" value={localStorage.getItem("lang")} onChange={onChangeLang} name="lang">
        {LANGUAGES.map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </Form.Control>
    </>
  );

}
export default ChangeLang;
