import React, { Component } from "react";
import Footer from "../Common/Footer";
import LoginHeader from "../Common/LoginHeader";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import { baseUrl } from "../Common/BaseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import { toast,ToastContainer } from "react-toastify";
import { ProductName } from "../Common/ProductName";

export class Swap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ct: "",
      pt: "",
      ctChangeAmount: "",
      convertTotal: "",
    };
  }

  componentDidMount() {
    this.getCoinDetails();
  }

  getCoinDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.get(baseUrl + "/frontapi/getCoinDetails", config).then((resp) => {
      var resp = resp.data;
      this.setState({
        ctImg: resp.data[0].coin_icon,
        ptImg: resp.data[1].coin_icon,
        ctAmount: resp.data[0].main[0].coin_amount,
        ptAmount: resp.data[1].main[0].coin_amount,
        ctId:resp.data[0].coin_id,
        ptId:resp.data[1].coin_id
      });
      
    });
  };

  changeCalculate = (e) => {
    let { name, value } = e.target;
    if (name == "ctChangeAmount") {
      var convertTotal = parseFloat(value) / 10000;
    }
    this.setState({ [name]: value, convertValue: convertTotal });
  };

  handleClickDelete=()=> {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let totalValues = {
      coinId: this.state.ctId,
      coinAmount:this.state.ctChangeAmount,
      converTedValue:this.state.convertValue
    }

    axios.post(baseUrl + "/frontapi/convertCoin",totalValues,config).then(resp=>{
      var resp =  resp.data
      if(resp.status == false){
        toast.error(resp.message)
      }
      if(resp.status == true){
        toast.success("Amount Transferd Successfuly!")
        this.getCoinDetails()
        this.setState({ctChangeAmount:"",convertValue:""})
      }
    })
  }

  deleteRecordMgs =() => {
    if(!this.state.ctChangeAmount){
      toast.error("Please enter Value First!")
      return
    }
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to convert this token ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleClickDelete(),
        },
        {
          label: "No",
        },
      ],
    });
  }

  render() {
    const meta = {
      title: `Swap | ${ProductName}  Exchange`,
      description:
      `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    return (
      <DocumentMeta {...meta}>
      <div>
        <LoginHeader />
        <div className=" dashbord_manbox ">
          <div className="container">
            <div className="walletClass"></div>
            <div className="banner_box">
              <h4 className="tc h4_hadding">Easy Token Convertor</h4>

              <div className="clearfix"></div>
              <div className="tab-content">
                <div className="tab-pane active" id="Staking_tab2">
                  <div className="row">
                    <div className="form-group col-sm-6 mb-md-4">
                      <div className="coin_box">
                        {this.state.ctImg == undefined ? (
                          <img src="assets/img/care.png" alt="icon1" />
                        ) : (
                          <img
                            src={
                              baseUrl +
                              "/static/currencyImage/" +
                              this.state.ctImg
                            }
                            alt="CtImage"
                          />
                        )}
                        <span>
                          <h5>Care Token</h5>
                          {this.state.ctAmount == "null" ||
                          this.state.ctAmount == undefined ? (
                            <h6>Balance: 0</h6>
                          ) : (
                            <h6>Balance: {this.state.ctAmount}</h6>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-sm-6 mb-md-4">
                      <div className="coin_box">
                        {this.state.ptImg == undefined ? (
                          <img src="assets/img/prince_t.png" alt="icon1" />
                        ) : (
                          <img
                            src={
                              baseUrl +
                              "/static/currencyImage/" +
                              this.state.ptImg
                            }
                            alt="PtImg"
                          />
                        )}

                        <span>
                          <h5>Prince Token</h5>
                          {this.state.ptAmount == "null" ||
                          this.state.ptAmount == undefined ? (
                            <h6>Balance: 0</h6>
                          ) : (
                            <h6>Balance: {this.state.ptAmount}</h6>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-sm-5">
                      <h5>Care Token</h5>

                      <input
                        type="number"
                        id="busdAmt"
                        placeholder="Amount"
                        onkeyup="busdPrice()"
                        value={this.state.ctChangeAmount}
                        onChange={this.changeCalculate}
                        name="ctChangeAmount"
                        className="control_new"
                      />
                    </div>
                    <div className="col-md-2">
                      {/* <div className="exchange_icon">
                        <i className="fa fa-exchange"></i>
                      </div> */}
                    </div>
                    <div className="form-group col-sm-5">
                      <h5>Prince Token</h5>
                      <input
                        type="number"
                        className="control_new"
                        onkeyup="reverceBusdPrice()"
                        id="tokenAmt2"
                        placeholder="Amount"
                        value={this.state.convertValue}
                        name="convertTotal"
                        disabled
                      />
                    </div>
                    <div className=" form-group col-sm-12 tc mt-4">
                      <button
                        className="btn busdBuy"
                        onClick={this.deleteRecordMgs}
                      >
                        Convert
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Footer />
      </div>
      </DocumentMeta>
    );
  }
}

export default Swap;
