import React from "react";
import LoginHeader from "../Common/LoginHeader";
import { Link } from "react-router-dom";
import Footer from "../Common/Footer";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { Modal, Button } from "react-bootstrap";

const baseUrl = myConstList.baseUrl;

export class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount = () => {
    this.GetUserData();
    this.ShowListData();
  };

  GenerateApi = () => {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 50;
    var randomstring = "";
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.setState({ ApiKey: randomstring });

    var Api_Key = {
      userid: localStorage.getItem("id"),
      GeneratedApi: randomstring,
    };

    const config = {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/GenerateApi", Api_Key, config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({ msg: resp.message });
        if (resp.status === true) {
          this.ShowListData();
        }
      });
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });
  };

  GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };

    // var UserID={
    //     userId:localStorage.id,
    // }
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      this.setState({ Whitelist_Status: resp.data[0].whitelist_status });
      // console.log("user Data", resp.data[0].whitelist_status);
    });
  };

  ShowListData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var walletAddress = {
      userId: localStorage.getItem("id"),
    };

    axios
      .post(baseUrl + "/frontapi/apiKeyData", walletAddress, config)
      .then((resp) => {
        var resp1 = resp.data;
        this.setState({ msg: resp.message });
        // console.log("whishlst status", resp.data);
        if (resp1.status === true) {
          this.setState({ TableData: resp1.data, TableStatus: resp1.status });
          // console.log(this.setState.TableData);
        }
      });
  };

  ApiKeysListHtml = () => {
    if (this.state.TableStatus === true) {
      const html = [];
      const classThis = this;
      this.state.TableData.map(function (value, i) {
        if (value.status === "0") {
          var Api_status = "Disable";
        } else {
          var Api_status = "Enable";
        }
        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>{value.apiKeys}</td>
            <td>{Api_status}</td>
            <td></td>
            <td></td>
            <td>
              <div
                className="btn2 btn"
                onClick={() => classThis.deleteKey(value.api_id)}
              >
                Delete
              </div>
            </td>
          </tr>
        );
      });

      return <tbody className="">{html}</tbody>;
    }
  };

  deleteKey = (getMethodDeleteId) => {
    //this.Delete_Apikey(getMethodDeleteId)
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.Delete_Apikey(getMethodDeleteId),
        },
        {
          label: "No",
          onClick: () => this.checkCall,
        },
      ],
    });
  };
  Delete_Apikey = (api_id) => {
    // var api_id= api_id;

    let Payment_delete_Data = {
      api_id: api_id,
      userId: localStorage.getItem("id"),
    };
    // const config = {
    //   headers: {
    //     "x-access-token": localStorage.getItem("token"),
    //   },
    // };

    axios
      .post(baseUrl + "/frontapi/deleteApiKey", Payment_delete_Data)
      .then((resp) => {
        this.setState({ message: resp.data.message });
        if (resp.data.status === true) {
          this.ShowListData();
        }
      });
  };

  render() {
    var showStatus = "";

    if (this.state.Whitelist_Status === 0) {
      showStatus = "Enable";
    } else if (this.state.Whitelist_Status === 1) {
      showStatus = "Disable";
    }

    return (
      <div className="">
        <div className="bghwhite">
          <LoginHeader id={"disallow"} />

          <div className="container container2">
            <div className="in_page">
              <div className="row">
                <div className="col-md-3">
                  <div className="left_tab">
                    <ul>
                      <li>
                        <Link to="/General">General settings</Link>
                      </li>
                      <li>
                        <Link to="/Whitelist">Whitelist</Link>
                      </li>
                      <li>
                        <Link to="/Security">Security</Link>
                      </li>
                      {/*<li>
                        <Link to="/frontapiKeys" className="active">
                          API keys
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/Kyc">KYC</Link>
                      </li>
                      {/* <li>
                        <Link to="/Verification">Account Verification</Link>
                      </li> */}
                      <li>
                        <Link to="/Google_auth">Google Authentication</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-9 padding_d">
                  <h5>Your API keys</h5>
                  <div className="white_box fs12">
                    <div className="row">
                      <div className="form-group col-md-8">
                        The HitBTC API provides access to market data and
                        trading operations
                        <p>
                          For more information, please refer to{" "}
                          <a href="#" className="a_color">
                            Exchange API documentation
                          </a>
                        </p>
                      </div>

                      <div className="form-group col-md-4 tr">
                        <button
                          className="btn btn_man fs14"
                          onClick={this.GenerateApi}
                        >
                          New API key
                        </button>
                      </div>
                    </div>

                    <div
                      className="tc"
                      style={{ overflow: "scroll", height: "400px" }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Keys</th>
                            <th>Status</th>
                            <th>Last use </th>
                            <th>Access Rights </th>
                            <th>Del</th>
                          </tr>
                        </thead>
                        {this.ApiKeysListHtml()}
                      </table>
                      Please add one or more API keys to your account
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <ToastContainer />
      </div>
    );
  }
}

export default ApiKeys;
