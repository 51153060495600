import * as axios from 'axios';

const post = async (url = '', data, token=false) => {
    if(token){
        token = 'Bearer '+token;
    }
    let response = await axios.post(url, data, { headers: { Authorization: token }});
    return response;
}
const put = async (url = '', data, token=false) => {
    if(token){
        token = 'Bearer '+token;
    }
    let response = await axios.put(url, data, { headers: { Authorization: token }});
    return response.data;
}
const get = async (url = '',  token=false) => {
    if(token){
        token = 'Bearer '+token;
    }
    let response = await axios.get(url, { headers: { Authorization: token }});
    return response.data;
}
const deleteData = async (url = '',data, token=false) => {
    if(token){
        token = 'Bearer '+token;
    }
    let response = await axios.delete(url,data, { headers: { Authorization: token }});
    return response.data;
}
export {
    post,
    get,
    deleteData,
    put
}