
import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import enTranslation from "./helpers/en/translation";
import arTranslation from "./helpers/ar/translation";
import koTranslation from "./helpers/ko/translation";
import perTranslation from "./helpers/per/translation";
import rusTranslation from "./helpers/rus/translation";


i18n
  .use(i18nBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem("lang"),
    lng: localStorage.getItem("lang"),
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation:enTranslation} ,
      ar: {
        translation: arTranslation
      },
      ko: {
        translation:koTranslation ,
      },
      per: {
        translation:perTranslation ,
      },
      rus: {
        translation:rusTranslation ,
      },
    },
  });

export default i18n;