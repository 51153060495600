import React from "react";
import ForgotForm from "./LoginElement/ForgotForm";
import Nav from "../Common/Nav"
import MainHeader from "../Common/MainHeader";

const Forgot = () => {
    return (
        <div>
             <MainHeader />
            <ForgotForm />
        </div>
    )
}

export default Forgot;