import React, { useEffect, useState } from "react"
import axios from "axios";
import { Component } from "react";
import { useTranslation } from "react-i18next";
import MainHeader from "../Common/MainHeader";
import { baseUrl } from "../Common/BaseUrl"
import Footer from "../Common/Footer";
// import DocumentMeta from 'react-document-meta';
// import { ProductName } from "./Common/ProductName";



const MarketToken = () => {
    const { t } = useTranslation();

    const [faqList, setFaqList] = useState([])
    const [tokenList, setTokenList] = useState({ current: [], upcoming: [] });
    const [key, setKey] = useState('current');

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        axios.post(baseUrl + "/frontapi/sale_market_token").then((res) => {
            if (res.data.status) {
                setTokenList(res.data.data)
            }
        }).catch((err) => {

        })
    }

    return (
        <>
            <div>
                <MainHeader />
                <div className="privacy_policy faqq">
                    <div className="container">

                        <div className="row">

                            <div className="col-md-3 col-xs-3">

                                <h1 className="mb-3">{t("Sale Market")}</h1>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-3 col-xs-3">
                                <p className={"default-token " + (key == "current" ? "selected-token" : "")} onClick={(k) => setKey("current")}>{t("Current")}</p>
                            </div>
                            <div className="col-md-3 col-xs-3">
                                <p className={"default-token " + (key == "upcoming" ? "selected-token" : "")} onClick={(k) => setKey("upcoming")}>{t("Upcoming")}</p>
                            </div>
                        </div>
                        {(key == "current") ?
                            <div className="row">
                                {tokenList.current.map((val, index) =>
                                    <div className="col-md-2 col-xs-3">
                                        <a className="card" href={"coinExchange/"+val.id}>
                                            <div className="card-header" role="tab">
                                                <img src={baseUrl + "/static/market_token/" + val.symbol} style={{ width: "135px" }} />
                                                <div>{val.name}</div>
                                                <p>{val.price}</p>

                                            </div>


                                        </a>
                                    </div>
                                )}
                            </div>
                            :
                            <div className="row">
                                {tokenList.upcoming.map((val, index) =>
                                    <div className="col-md-2 col-xs-3">
                                        <a className="card" href={"coinExchange/"+val.id}>
                                            <div className="card-header" role="tab" id={"headingOne" + index}>
                                                <img src={baseUrl + "/static/market_token/" + val.symbol}   style={{ width: "135px" }} />
                                                <p>{val.name}</p>
                                                <p>{val.price}</p>

                                            </div>


                                        </a>
                                    </div>
                                )}
                            </div>

                        }

                    </div>
                </div>
            </div >

            <Footer />
        </>
    );

}
export default MarketToken

