import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as myConstList from "../../Common/BaseUrl";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleLogin } from "@react-oauth/google";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { EmailValidation } from "../../Validation/EmailValidation";
import { ProductName } from "../../Common/ProductName";
import DocumentMeta from "react-document-meta";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { connect } from "formik";
const baseUrl = myConstList.baseUrl;

export const LoginForm = () => {
  const [type, setType] = useState("password");
  const [LoginStatus, setLoginStatus] = useState("");
  const [loginVia, setLoginVia] = useState("Password");
  const [countryCode, setCountryCode] = useState("+");
  const [timerText, setTimerText] = useState("Get Code");
  const [counter, setCounter] = useState(0);
  const [buttonDisableVerification, setButtonDisableVerification] =
    useState(true);
  const [disable, setDisable] = useState(false);
  const [pair, setPair] = useState("");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [device, setDevice] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [vcode, setVCode] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");
  const [color, setcolor] = useState("red");
  const [textForCode, setTextForCode] = useState("");
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [inputMobile, setInputMobile] = useState("");
  const [automateCountryCode, setAutomateCountryCode] = useState("ae");

  const [showSwitchButton, setShowSwitchButton] = useState("email");
  const { t } = useTranslation();
  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    setAutomateCountryCode(
      data.country_code ? data.country_code.toLowerCase() : "ae"
    );
    localStorage.setItem("ip", data.ip ? data.ip : "");
  };

  const getOpenPair = async () => {
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          setPair(
            `${res.data.data[0].first_name}/${res.data.data[0].second_name}`
          );
        }
      })
      .catch((err) => { });
  };
  const handleClick = () => {
    setType((prevType) => (prevType === "text" ? "password" : "text"));
  };

  const handleOnBlurPassword = (event) => {
    let passwordError = "";
    var Password = event.target.value;
    if (!Password) {
      passwordError = "This field is required";
    }
    setPassword(Password);
    setPasswordError(passwordError);
  };

  const setupGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryName(data.country_name);
        setCityName(data.city);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setupDeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
    }
    if (isAndroid) {
      setDevice("Android");
    }
    if (isIOS) {
      setDevice("IOS");
    }
    if (isDesktop) {
      setDevice("Desktop");
    }
  };

  useEffect(() => {
    getData();
    getOpenPair();
    setupDeviceName();
    setupGeoInfo();
    if (localStorage.checkbox && localStorage.email !== "") {
      setEmail(localStorage.email);
      setPassword(localStorage.password);
    }
  }, []);
  const handleChange1 = (event) => {
    let { name, value } = event.target;

    // console.log(value);


    if (name === "password") {
      let error = "";
      if (!value) {
        error = "This field is required";
        setPasswordError(error);
      } else {
        setPasswordError(error);
      }
      setPassword(value);
    }
    if (name === "verification_code") {
      if (!value) {
        setVerificationCodeError("This field is required");
      } else {
        setVerificationCodeError("");
      }
      if (value.length > 6) {
        value = value.toString().substring(0, 6)
      }
      setVerificationCode(value);
    }
    // Handle other input fields similarly...
    if (name !== "password" && name !== "verification_code") {
      // Handle other fields...
      if (name === "email") {
        setEmail(value);
      }
    }
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    if (!vcode) {
      setMessage("This field is required");
      return;
    }
    let LoginData = {
      Password: password,
      code: vcode,
    };
    if (mobile) {
      LoginData.mobile = mobile;
    }
    if (email) {
      LoginData.email = email;
    }
    try {
      axios
        .post(baseUrl + "/frontapi/verifyLoginGoogleCode", LoginData)
        .then((resp) => {
          setMessage(resp.data.message);

          if (resp.data.status == true) {
            setcolor("green");
            localStorage.setItem("token", resp.data.token);
            // window.location.href = "/exchange/BTC/USDT";
            window.location.href = "/";
          } else {
            setcolor("red");
          }
        });
    } catch (error) { }
  };

  const startCountdown = (seconds) => {
    let counter = seconds;
    const interval = setInterval(() => {
      setCounter(counter);
      setBtnDisable(true);
      setTimerText("Resend In");
      counter--;
      if (counter < 0) {
        clearInterval(interval);
        setTimerText("");
        setTextForCode("Resend Code");
        setBtnDisable(false);
      }
    }, 1000);
  };

  const getCode = async () => {
    if (!email && !mobile) {
      setEmailError("This field is required");
      return false;
    }
    const registerData = {
      countryCode: countryCode,
      page: "login",
      selectedFiledType: selectedFiledType,
    };
    if (selectedFiledType === "email" && email) {
      registerData.email = email;
      if (!/^[a-zA-Z0-9][a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email)) {
        setEmailError("Please enter a valid email address");
        return false;
      }
    }
    if (selectedFiledType === "mobile" && mobile) {
      registerData.mobile = mobile;
      const isValidLength = mobile.length >= 5 && mobile.length <= 15;
      if (!isValidLength) {
        setMobileError("Please enter a valid mobile number");
        return false;
      } else {
        setMobileError("");
      }
    }
    try {
      await axios
        .post(baseUrl + "/frontapi/getcode", registerData)
        .then((res) => {
          var resp = res.data;

          setVerificationCodeError("");

          if (resp.status === true) {
            toast.success(t(resp.message));
            startCountdown(30);
            setBtnDisable(false);
            setButtonDisableVerification(false);
            return false;
          }
          if (resp.status === false) {
            setBtnDisable(false);
            toast.error(t(resp.message));
            setButtonDisableVerification(true);
            return false;
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    if (textForCode == "Resend Code") {
      setTextForCode("Get Code");
    }
    setEmail(value);
    setMobile(value);
    if (!value) {
      setEmailError("This field is required");
      setMobileError("");
      return false;
    }

    if (!value) {
      setMobileError("");
      setEmailError("");
      // setPlaceholder("Verification Code");
      setMobile("");
      setEmail("");
      return false;
    }

    if (
      !isNaN(parseFloat(event.target.value)) &&
      !isNaN(event.target.value - 0)
    ) {
      const phoneno = /^([0|\+[0-9]{1,10})?([0-9]{7})$/;
      if (value.match(phoneno)) {
        setMobileError("");
        setEmailError("");
      } else {
        setMobileError("Please enter a valid mobile number");
        setEmailError("");
      }
      setMobile(event.target.value);
      setEmail("");
      // setPlaceholder("Enter Mobile Code");
    } else {
      const Email = event.target.value;
      const lowercaseEmail = Email.toLowerCase();
      if (!lowercaseEmail) {
        setEmailError("This field is required");
        setMobileError("");
        return false;
      }
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(lowercaseEmail)) {
        setEmailError("");
        setMobileError("");
      } else {
        setEmailError("Please enter a valid email address");
        setMobileError("");
      }
      setEmail(lowercaseEmail);
      setMobile("");
    }
  };

  const handleAuthChange = (e) => {
    setVCode(e.target.value);
    if (!e.target.value) {
      setMessage("This field is required111");
    } else {
      setMessage("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);

    setTimeout(() => {
      setDisable(false);
    }, 4000);

    if (selectedFiledType === "email") {
      if (!email) {
        setEmailError("This field is required");
        return false;
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setEmailError("Please enter a valid email address");
        return false;
      }

      if (loginVia === "OTP" && !verificationCode && !email) {
        setVerificationCodeError("This field is required");
        setEmailError("This field is required");
        return false;
      }

      if (loginVia === "Password" && !email) {
        setEmailError("This field is required");
        return false;
      }
    } else {
      if (!countryCode || countryCode.length < 2) {
        setCountryCodeError("required");
        return false;
      }

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(countryCode)) {
        setCountryCodeError("Please enter a valid country code");
        return;
      }

      if (!mobile) {
        setMobileError("This field is required");
        return false;
      }

      if (mobile.length < 5 || mobile.length > 15) {
        setMobileError("Mobile number should be between 5 to 15 characters");
        return false;
      }

      if (loginVia === "OTP" && !verificationCode && !mobile) {
        setVerificationCodeError("This field is required");
        setMobileError("This field is required");
        return false;
      }

      if (loginVia === "Password" && !mobile) {
        setMobileError("This field is required");
        return false;
      }

      if (loginVia === "OTP" && !verificationCode) {
        setVerificationCodeError("This field is required");
        return false;
      }
    }

    if (loginVia === "Password" && !password) {
      setPasswordError("This field is required");
      return false;
    }

    if (mobileError || verificationCodeError || passwordError || emailError) {
      return false;
    }
    let values = {};

    if (loginVia === "Password") {
      values = {
        Password: password,
        selectedFiledType: selectedFiledType,
      };
    } else {
      values = {
        verification_code: verificationCode,
        selectedFiledType: selectedFiledType,
      };
    }
    if (email) {
      values.email = email;
    }
    if (mobile) {
      values.mobile = mobile;
      values.country_code = countryCode;
    }
    try {
      const resp = await axios.post(baseUrl + "/frontapi/login", values);
      const responseData = resp.data;
      console.log(responseData);
      setPasswordError(responseData.messagePass);
      setMessage(responseData.messagee);
      setMessage(responseData.message);

      if (responseData.status === false) {
        toast.dismiss();
        toast.error(t(responseData.message), { autoClose: 2000 });
        return;
      }
      if (responseData.status === true) {

        localStorage.setItem("userData", JSON.stringify(responseData.data[0]));

        localStorage.setItem(
          "id",
          responseData.data[0] ? responseData.data[0].id : ""
        );
        localStorage.setItem(
          "mobile",
          responseData.data[0] ? responseData.data[0].mobile : ""
        );

        if (responseData.token !== undefined) {
          localStorage.setItem(
            "token",
            responseData.token ? responseData.token : ""
          );
          localStorage.setItem(
            "mobile",
            responseData.data[0] ? responseData.data[0].mobile : ""
          );
          localStorage.setItem(
            "id",
            responseData.data[0] ? responseData.data[0].id : ""
          );
          localStorage.setItem(
            "x-session-id",
            responseData.data[0] ? responseData.sessionId : ""
          );
          toast.dismiss();

          toast.success(t("Login Successfully"));
          const config = {
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          };
          var userIdDataIp = {
            ip: localStorage.getItem("ip"),
            device: device,
            browser: browserName + " " + browserVersion,
            osName: osName + " " + osVersion,
            cityName: cityName,
            countryName: countryName,
          };

          async function getLoginInsert() {
            const response = await axios.post(
              baseUrl + "/frontapi/login-insert",
              userIdDataIp,
              config
            );
          }
          getLoginInsert();
          setTimeout(() => {
            // window.location.href = `/exchange/${pair}`;
            window.location.href = `/`;
          }, 1000);
        } else {
          setLoginStatus(true);
        }
      }
      return;
    } catch (error) {
      // Handle the error here
    }
  };

  const handleToggleClick = () => {
    if (loginVia === "Password") {
      setLoginVia("OTP");
      setVerificationCode("");
      // setMobileError("");
      // setEmailError("");
      setPasswordError("");
      setVerificationCodeError("");
    } else {
      setLoginVia("Password");
      // setMobileError("");
      setPassword("");
      setPasswordError("");
      setPassword("");
      setVerificationCodeError("");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const data = {
        token: tokenResponse.code,
      };
      const userInfo = await axios.post(
        baseUrl + "/frontapi/google-login",
        data
      );
      console.log("userInfo", userInfo.data);
      if (userInfo.data) {
        localStorage.setItem(
          "id",
          userInfo.data.data[0] ? userInfo.data.data[0].id : ""
        );
        localStorage.setItem(
          "mobile",
          userInfo.data.data[0] ? userInfo.data.data[0].mobile : ""
        );
        localStorage.setItem(
          "token",
          userInfo.data.token ? userInfo.data.token : ""
        );
        toast.dismiss();
        toast.success(t("Login Successfully"));
        //  window.location.href = `/exchange/${pair}`;
        window.location.href = `/`;
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: "auth-code",
  });

  const handleOnChange = (value, country) => {
    console.log("Selected Country:", country);
    console.log("Formatted Phone Number:", value);
    const effectiveValue = value.replace(/[\s\+]/g, ""); // Remove spaces and plus signs for the check
    const isEffectivelyEmpty = effectiveValue === country.dialCode;
    setEmailError("");
    if (isEffectivelyEmpty) {
      setSelectedFiledType("email"); // Switch back to email input if only the country code is present
      setCountryCode("");
      setMobile("");
      setEmailError("This filed is required");
    } else {
      setSelectedFiledType("mobile"); // Otherwise, stay on mobile input
      setEmailError("");
    }

    // if (!value) {
    //   setSelectedFiledType("email");
    // } else {
    //   setSelectedFiledType("mobile");
    // }

    if (textForCode == "Resend Code") {
      setTextForCode("Get Code");
    }

    let adjustedMobile = value.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    setMobile(adjustedMobile);
    setCountryCode("+" + country.dialCode);
    setMobileError("");
    setEmail("");
  };

  const handleChangeMobile = (event) => {
    const { name, value } = event.target;

    setEmailError("");
    setEmail("");
    setCountryCodeError("")
    if (name === "phone") {
      setMobile(value);

      if (!countryCode) {
        setCountryCodeError("required");
        return;
      }

      if (!value) {
        setMobileError("This field is required");
        return;
      }

      if (value.length < 5 || value.length > 15) {
        setMobileError("Mobile number should be between 5 to 15 characters");
        return;
      }

      // setSelectedFiledType("mobile"); // Otherwise, stay on mobile input

      setEmailError("");
      setMobileError("");
      setCountryCodeError("")

      // if (!value) {
      //   setSelectedFiledType("email");
      // } else {
      //   setSelectedFiledType("mobile");
      // }

      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
      }

      // setMobile(value);
      // setCountryCode("+" + country.dialCode);
      // setMobileError("");
    }

    if (name === "countryCode") {
      if (!value || value.length < 2) {
        setCountryCode("+");
        setCountryCodeError("required");
        return;
      }

      setCountryCode(value);

      const regex = /^\+\d{1,4}$/;

      if (!regex.test(value)) {
        // setCountryCode("+");
        setCountryCodeError("Please enter a valid country code");
        return;
      }

      setMobileError("");
    }
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    console.log("working hgere", name);
    if (name === "Email") {
      if (textForCode == "Resend Code") {
        setTextForCode("Get Code");
      }
      const isEmail = /\S+@\S+\.\S+/.test(value);
      const isMobile = /^\d+$/.test(value);
      if (isMobile) {
        setSelectedFiledType("mobile");
        setInputMobile(value);
        setMobile(value);
        setEmailError("");
        return;
      } else if (isEmail) {
        setSelectedFiledType("email");
      }
      setEmail(value);
      const emailvalidation = EmailValidation(name, value);
      setEmailError(emailvalidation);
      setMobileError("");
    }
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    if (name === "Email") {
      if (!value) {
        setEmailError("This field is required");
      }
    } else {
      if (!value) {
        setMobileError("This field is required");
      }
    }
  };

  const switchButton = (value) => {
    if (counter == 0) {
      setMobile("");
      setEmail("");
      setCountryCode("+");
      setEmailError("");
      setMobileError("");
      if (value === "email") {
        setSelectedFiledType("email");
      }

      if (value === "mobile") {
        setSelectedFiledType("mobile");
      }
    }
  };

  const meta = {
    title: "Low Cost Crypto Exchange App| Bitcoin Exchange",
    description: `From involving a large number of coins to executing voluminous orders, this platform takes care of everything. ${ProductName} Live Does The Buying And Selling Of Crypto Coin.`,
    canonical: `https://${ProductName}.com/login-to-buy-sell-crypto`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Crypto Exchange, Crypto Buying Selling, Buy Sell Bitcoin, BTC Exchange",
      },
    },
  };

  const keyPress = (e) => {
    //  console.log("e ",e.keyCode);
    //  console.log("ctl ",e.ctrlKey);
    if (verificationCode.length > 5) {
      if (e.key != "Backspace" && (!e.ctrlKey && e.keyCode != 86)) {
        e.preventDefault()
      }
    }
  }

  return (
    <>
      <DocumentMeta {...meta}>
        <div className="">
          <div className="register-main-outer position-relative  ">
            <span className="main-bg fadeInLeft animated"></span>
            <div className="register-inner">
              <div className="row m-0">
                <div className="col-md-5 col-12 m-auto">
                  <div className="register-right fadeInRight animated ">
                    <div className="heading-link-outer">
                      <div className="heading d-sm-flex mb-2 align-items-center">
                        <h3 className=" ">{t("login")}</h3>
                        <div className="login_tab d-flex d-sm-inline-flex ml-auto text-center">
                          <a
                            className={
                              selectedFiledType === "email" ? "active" : ""
                            }
                            onClick={() => switchButton("email")}
                          >
                            {" "}
                            {t("Email")}
                          </a>
                          <a
                            className={
                              selectedFiledType === "mobile" ? "active" : ""
                            }
                            onClick={() => switchButton("mobile")}
                          >
                            {" "}
                            {t("Mobile Number")}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="register-form">
                      {LoginStatus == false || LoginStatus == "" ? (
                        <form>
                          <div className="form-group position-relative">
                            {/* <label className="form-label">
                              Email or Mobile Number
                            </label> */}
                            {selectedFiledType === "mobile" ? (
                              <>
                                <label className="form-label">
                                  {t("Mobile Number")}
                                </label>

                                <div className="row">
                                  <div className="col-3">
                                    <input
                                      className="form-control"
                                      type="text"
                                      autoComplete="off"
                                      placeholder={t("Country Code")}
                                      name="countryCode"
                                      autoFocus
                                      value={countryCode}
                                      onChange={handleChangeMobile}
                                    // disabled={counter == 0 ? false : true}
                                    />
                                    {countryCodeError && (
                                      <div style={{ color: "red" }} name="mobile">
                                        {t(countryCodeError)}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-9 pl-0">
                                    <input
                                      className="form-control"
                                      type="number"
                                      autoComplete="off"
                                      placeholder={t("enter") + " " + t("Mobile Number")}
                                      name="phone"
                                      autoFocus
                                      value={mobile}
                                      onChange={handleChangeMobile}
                                      disabled={counter == 0 ? false : true}
                                    />
                                    {mobileError && (
                                      <div style={{ color: "red" }} name="mobile">
                                        {t(mobileError)}
                                      </div>
                                    )}
                                  </div>
                                </div>


                                {/* <PhoneInput
                                  key={"phoneInput"}
                                  country={automateCountryCode} // Default country (you can change it based on user's location)
                                  onChange={handleOnChange}
                                  // onBlur={handleOnBlur}
                                  countryCodeEditable={false}
                                  className="form-control country-input"
                                  placeholder="Mobile"
                                  enableSearch={true}
                                  name="phone"
                                  inputProps={{
                                    autoFocus: true,
                                  }}
                                  value={inputMobile}
                                  disabled={counter == 0 ? false : true}
                                /> */}
                              </>
                            ) : (
                              <>
                                <label className="form-label" dir="rtl" lang="ar">{t("Email")}</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  placeholder={t("enter") + " " + t("Email")}
                                  name="Email"
                                  autoFocus
                                  value={email}
                                  maxLength="64"
                                  onChange={handleChangeEmail}
                                  // onPaste={(e) => e.preventDefault()}
                                  disabled={counter == 0 ? false : true}
                                />
                              </>
                            )}

                            {emailError && (
                              <div style={{ color: "red" }} name="email">
                                {t(emailError)}
                              </div>
                            )}
                          </div>

                          {loginVia == "Password" ? (
                            <div className="form-group">
                              <label className="form-label">{t("password")}</label>
                              <div className="eye_pass">
                                <input
                                  className="form-control"
                                  autoComplete="off"
                                  type={type}
                                  name="password"
                                  id="password"
                                  maxLength="20"
                                  placeholder={t("enter") + " " + t("password")}
                                  value={password}
                                  onChange={handleChange1}
                                  onBlur={handleOnBlurPassword}
                                />
                                <span style={{ color: "red" }}>
                                  {t(passwordError)}
                                </span>

                                <span
                                  className="password__show eye1"
                                  onClick={handleClick}
                                >
                                  {type === "text" ? (
                                    <i className="fa fa-eye"></i>
                                  ) : (
                                    <i className="fa fa-low-vision"></i>
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="form-group">
                              <div className="input-group">

                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={t("Enter Verification Code")}
                                  name="verification_code"
                                  id="verification_code"
                                  value={verificationCode}
                                  onChange={handleChange1}
                                  disabled={buttonDisableVerification}
                                  onBlur={handleOnBlurPassword}
                                  aria-label="Input group example"
                                  aria-describedby="btnGroupAddon"
                                  maxlength="6"
                                  max="999999"
                                  onKeyDown={(e) => { keyPress(e) }}
                                />
                                <div className="input-group-prepend">
                                  <button
                                    className="btn btn_man"
                                    id="btnGroupAddon"
                                    type="button"
                                    onClick={getCode}
                                    disabled={btnDisable}
                                  >
                                    {t(timerText)}
                                    {"  "}
                                    {counter == 0 ? textForCode : counter}
                                  </button>
                                </div>
                              </div>
                              <div style={{ color: "red" }}>
                                {verificationCodeError}
                              </div>
                            </div>
                          )}
                          <div className="d-flex mb-3">
                            <div className="via-otp">
                              <Link onClick={handleToggleClick}>
                                {t("via") + " "}
                                {loginVia === "Password" ? t("otp") : t("password")}
                              </Link>
                            </div>

                            <div className="ml-auto">
                              <div className="forgot-btn text-right">
                                <Link to="/forgot" className="a_color">
                                  {t("forgot_password")}
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="form-group log-btn mx-auto">
                            <button
                              className="btn w100 btn_man"
                              type="submit"
                              disabled={disable}
                              onClick={handleSubmit}
                            >
                              {t("login")}
                            </button>
                          </div>
                          <div className="form-group text-center">{t("or")}</div>
                          <div className="form-group log-btn mx-auto">
                            <button
                              className="btn w100 btn_border"
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                googleLogin();
                              }}
                            >
                              <img
                                src={"/assets/img/google.svg"}
                                alt=""
                                className="google_icon mr-2"
                              />{" "}
                              {t("continue_with_google")}{" "}
                            </button>
                          </div>

                          <div className="form-group text-center">
                            <span>{t("don_t_have_an_account_lets")} </span>
                            <Link
                              to="/register-for-bitcoin-exchange"
                              className=""
                            >
                              {t("sign_up")}
                            </Link>
                          </div>
                        </form>
                      ) : (
                        <div className="">
                          <h5>Two Factor Authentication</h5>
                          <form>
                            <div className="form-group">
                              <label className="fw-500">Enter Auth Code</label>
                              <input
                                type="text"
                                className="form-control"
                                name="vcode"
                                placeholder="Enter Code"
                                value={vcode}
                                onChange={handleAuthChange}
                              />
                            </div>
                            <div className="form-group">
                              <button
                                onClick={verifyCode}
                                className="btn btn_man w100px"
                              >
                                Verify
                              </button>
                              <p style={{ color }}>{t(message)} </p>
                            </div>
                          </form>
                        </div>
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer autoClose={3000} />
          </div>
        </div>
      </DocumentMeta>
    </>
  );
};
