import React, { Component } from "react";
import MainHeader from "../../Common/MainHeader";
import * as myConstList from "../../Common/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../Common/Footer";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";

const baseUrl = myConstList.baseUrl;

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      type: "password",
      type1: "password",
      userId:this.props.match.params.userId,
      token:this.props.match.params.token,
    };
  }

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  handleClick1 = () =>
    this.setState(({ type1 }) => ({
      type1: type1 === "text" ? "password" : "text",
    }));

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });
  };

  handleOnBlurPassword(event) {
    var password = event.target.value;
    var PwdErr = "";
    // eslint-disable-next-line
    var hasNumber = /\d/;

    if (!password) {
      toast.error("Enter valid Password");
      //PwdErr = "Enter valid Password";
    }
    if (password.length < 8) {
      toast.error("Password should be more than 8 Chacracters");
      // PwdErr = "Password should be more than 6 Chacracters"
      this.setState({ PwdErr: PwdErr });
      return false;
    }
    var regex = "^[a-zA-Z0-9_]*$";
    if (password.match(regex)) {
      toast.error(
        "Password should contain characters, number and special character."
      );
    }

    this.setState({
      password: password,
      PwdErr: PwdErr,
    });
  }
  handleOnBlurCPassword(event) {
    var C_Password = event.target.value;
    var CPwdErr = "";

    if (!C_Password) {
      toast.error("Enter valid Password");
      //CPwdErr = "Enter valid Password";
    }
    if (C_Password.length < 8) {
      toast.error("Enter Minimum 8 Character");
      // CPwdErr = "Enter Minimum 6 Character"
      this.setState({ CPwdErr: CPwdErr });
      return false;
    }

    if (this.state.Password === C_Password) {
      toast.success("Password matched");
      return false;
    } else {
      toast.error("Password miss  match");
    }
    this.setState({
      C_Password: C_Password,
      CPwdErr: CPwdErr,
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    

    axios.post(baseUrl + "/api/reset-password-user", {
      userId: this.state.userId,
      token: this.state.token,
      Password: this.state.Password,
      CPassword: this.state.CPassword
    }).then((resp) => {
     
      var resp = resp.data;
      if (resp.status === true) {
        window.location = "/login-to-buy-sell-crypto";
      }
      if (resp.status === false) {
        toast.error(resp.message);
        return;
      }
    });
  };

  responseHtml = () => {};

  render() {
    // eslint-disable-next-line
    const { label } = this.props;
    // eslint-disable-next-line
    const { email, password } = this.state;
    return (
      <div className="">
        <MainHeader />

        <div className="box5 p60">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <div className="boxbody login_box">
                  <h4 className="tc mb-4">Reset Password</h4>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <div className="eye_pass">
                        <input
                          className="form-control"
                          type={this.state.type1}
                          placeholder="New Password"
                          name="Password"
                          value={password}
                          onChange={this.handleChange}
                        
                        />

                        <span
                          className="password__show eye1"
                          onClick={this.handleClick1}
                        >
                          {this.state.type1 === "text" ? (
                            <i className="las la-eye"></i>
                          ) : (
                            <i className="las la-low-vision"></i>
                          )}
                        </span>

                        <p>{this.state.message}</p>
                        <div className="col-sm-12">
                          {" "}
                          <p className="errorsTag">
                            {this.state.respErrMsg}{" "}
                          </p>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="eye_pass">
                        <input
                          className="form-control py-3"
                          type={this.state.type}
                          name="CPassword"
                          id="CPassword"
                          placeholder="Confirm Password"
                          value={password}
                          onChange={this.handleChange}
                        />

                        <span
                          className="password__show eye1"
                          onClick={this.handleClick}
                        >
                          {this.state.type === "text" ? (
                            <i className="las la-eye"></i>
                          ) : (
                            <i className="las la-low-vision"></i>
                          )}
                        </span>
                      </div>

                      <p>{this.state.passwordError}</p>
                      <p className="errorsTag">{this.state.PasswordErr} </p>

                      <p className="errorsTag">{this.state.messagePassword} </p>
                    </div>

                    <div className="form-group">
                      <button className="btn w100 btn_man ">Reset Password</button>
                    </div>
                    <div className="row">
                      <div className="form-group col-sm-6">
                        <Link to="/forgot" className="a_color">
                          Forgot password
                        </Link>
                      </div>
                      <div className="form-group tr col-sm-6">
                        Not registered yet?{" "}
                        <Link to="/register" className="a_color">
                          SIGN UP
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <ToastContainer limit={1} />
      </div>
    );
  }
}

export default ResetPassword;
