import React from "react";
import MainHeader from "../Common/MainHeader";
import Footer from "../Common/Footer";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const baseUrl = myConstList.baseUrl;
class EmailAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respStatus: "",
      faType: "none",
      userId: "",
    };
    this.getUserDetails();
  }
  getCode = (event) => {
    // event.preventDefault();

    let registerData = {
      email: this.state.email,
    };

    axios.post(baseUrl + "/frontapi/getcode", registerData).then((resp1) => {
      var resp = resp1.data;
      this.setState({ Code: resp.code, EmailMessageErr: resp.message });

      if (resp.status === true) {
        toast.success("Please check your E-mail");
        this.setState({ textForCode: "Resend Code" });
        return false;
      }
      if (resp.status === false) {
        toast.error(resp.message);
        return false;
      }
    });
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, emptyField: false, errMsg: "" });
  };
  verifyCode = () => {
    if (localStorage.getItem("VerificationCode") === this.state.vcode) {
      this.props.history.push("/exchange/TRX/INR");
    }
    else{
      toast.error("Something Went Wronge!")
    }
    // axios.post(baseUrl + '/frontapi/verifyGoogleCode', faData, config).then((resp) => {
    //     this.setState({ message: resp.data.message });
    //     if (resp.status) {

    //     }
    // })
  };
  getUserDetails = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp1) => {});
  };
  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });
  };
  render() {
    return (
      <div className="">
        <MainHeader />

        <div className="box5 p60">
          <div className=" container">
            <div className="row">
              <div className="col-md-3 "></div>
              <div className="col-md-6 ">
                <div className="boxbody login_box">
                <h4 className="tc mb-4">E-Mail Auth</h4>
                    <div className="form-group">
                      <label className="fw-500">Enter Auth Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="vcode"
                        placeholder="Enter Code"
                        value={this.state.vcode}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        onClick={this.verifyCode}
                        className="btn btn_man w100px"
                      >
                        Verify
                      </button>
                      <p className="succTag">{this.state.message} </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer limit={1} />
      </div>
    );
  }
}

export default EmailAuth;
