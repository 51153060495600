import React from "react";
import { ToastContainer } from "react-toastify";
import Footer from "../Common/Footer";
import LoginHeader from "../Common/LoginHeader";
import SecurityContent from "./SecurityContent";

const Security = () => {
  return (
    <div>
      <div className="bghwhite">
        <LoginHeader id={"disallow"}/>
        <SecurityContent />
      </div>
      <Footer />
      <ToastContainer limit={1} />
    </div>
  );
};

export default Security;
