import React from "react";
import { LoginForm } from "./LoginElement/LoginForm";
import MainHeader from "../Common/MainHeader";

const Login = () => {
  return (
    <div>
      <MainHeader />
      <LoginForm />
    </div>
  );
};

export default Login;
