import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BuyBox({
  firstCoinNew,
  firstCoin,
  secondCoin,
  secondCoinBalance,
  removeTrailingZeros,
  setStopChanges,
  setOrderMethod,
  limit,
  market,
  handleBuySubmit,
  stopLimit,
  stopPriceBuy,
  handleBuyChange,
  orderMethod,
  disabledButton,
  order_id,
  handleKeyPress,
  type,
  currentPrice,
  buyPrice,
  buyAmount,
  bPBtn,
  buy_order_total,
  buy_commission,
  removeTrailingZerosT,
  feeAmyt,
  token,
  disabledBuuButtn,
  setBuyPercentage,
  setBuy_order_total,
  setBuyAmount,
  setBPBtn,
  setInExtype,
  setFeeAmyt
}) {
  const { t } = useTranslation();
  const handleBuyPercent = (percentAmt) => {
    setBuyPercentage(percentAmt);
    var userBalance = secondCoinBalance;
    var percentAmts =
      (parseFloat(percentAmt).toFixed(8) * parseFloat(userBalance).toFixed(8)) /
      100;
    let Amount = percentAmts * (100 / (100 + buy_commission));
    let subTotal = Number(Amount).toFixed(8) / Number(buyPrice);
    setFeeAmyt(parseFloat(percentAmts).toFixed(8));
    setBuy_order_total(Amount.toFixed(8));
    setBuyAmount(subTotal.toFixed(8));
    setBPBtn(percentAmt);
    setInExtype("include");
  };

  const AuthAction = () => {
    if (token) {
      return (
        <button
          className="btn w100  my_color_buy"
          type="submit"
          disabled={disabledBuuButtn}
        >
          {t("Buy")}
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border2 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">{t("sign_up")}</Link> {t("or")}{" "}
            <Link to="/login-to-buy-sell-crypto">{t("login")}</Link>
          </div>
        </div>
      );
    }
  };

  const buyBoxHtml = () => {
    return (
      <div className="buy-btc-outer buy-sell-outer border1">
        <div className="orderforms-hd">
          <div>
            <label className="ng-binding">
              {t("Buy")} {firstCoinNew != "" ? firstCoinNew : firstCoin}
            </label>
            <span className="f-fr ng-binding">
              {secondCoin} :{" "}
              {secondCoinBalance
                ? Number.isInteger(secondCoinBalance)
                  ? secondCoinBalance
                  : removeTrailingZeros(
                    parseFloat(secondCoinBalance).toFixed(8)
                  )
                : 0}
            </span>
          </div>
        </div>
        <ul className="type-list-outer d-flex justify-content-between">
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "limit");
                setOrderMethod("limit");
              }}
              href="#!"
              className={limit === true ? "active" : ""}
            >
              {t("Limit")}
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                setStopChanges(e, "market");
                setOrderMethod("market");
              }}
              href="#!"
              className={market === true ? "active" : ""}
            >
              {t("Market")}
            </a>
          </li>
          {/* <li>
              <a
                onClick={() => this.setStopChanges("stop")}
                href="javascript:voin(0)"
                className={stopLimit == true ? "active" : ""}
              >
                Stop Limit
              </a>
            </li>   */}
        </ul>
        <form
          name="buyForm"
          onSubmit={handleBuySubmit}
          autoComplete="off"
          className="ng-pristine ng-invalid ng-invalid-required"
        >
          <div className="orderforms-inputs ">
            {stopLimit === true && (
              <div className="field f-cb stop-limit">
                <label className="ng-binding">Stop Limit: </label>
                <div className="iptwrap leftBig limit">
                  <input
                    type="number"
                    step="any"
                    value={stopPriceBuy}
                    onChange={handleBuyChange}
                    name="stopPriceBuy"
                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                  />

                  <span className="unit ng-binding">{secondCoin}</span>
                </div>
                <div className="cls"></div>
              </div>
            )}
            <div className="field f-cb">
              <label className="ng-binding">{t("Price")}: </label>
              <div className="iptwrap leftBig">
                {orderMethod === "market" ? (
                  <>
                    <input
                      type="text"
                      step="any"
                      id="pric"
                      value="Market"
                      onChange={handleBuyChange}
                      name="buyPrice"
                      className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                      disabled={disabledButton}
                      onKeyDown={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="order_id"
                      value={order_id}
                      name="order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                ) : (
                  <>
                    <input
                      type={type}
                      step="any"
                      id="price_buy"
                      value={disabledButton === true ? currentPrice : buyPrice}
                      onChange={handleBuyChange}
                      name="buyPrice"
                      className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                      disabled={disabledButton}
                      onKeyDown={handleKeyPress}
                    />
                    <input
                      type="hidden"
                      id="order_id"
                      value={order_id}
                      name="order_id"
                    />

                    <span className="unit ng-binding">{secondCoin}</span>
                  </>
                )}
              </div>
              <div className="cls"></div>
            </div>
            <div className="field f-cb">
              <label className="ng-binding">{t("Amount")}: </label>
              <div className="iptwrap">
                <input
                  type="number"
                  step="any"
                  name="buyAmount"
                  onChange={handleBuyChange}
                  value={buyAmount}
                  id="buyAmount"
                  aria-invalid="true"
                  onKeyDown={handleKeyPress}
                />
                <span className="unit ng-binding">{firstCoin}</span>
              </div>
              <div className="cls"></div>
            </div>
            <div className="field percent f-cb">
              <div className="field4">
                <span
                  className={`col ${bPBtn == 25 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(25)}
                >
                  25%
                </span>
                <span
                  className={`col ${bPBtn == 50 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(50)}
                >
                  50%
                </span>
                <span
                  className={`col ${bPBtn == 75 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(75)}
                >
                  75%
                </span>
                <span
                  className={`col ${bPBtn == 100 ? "my_color_buy" : ""}`}
                  onClick={() => handleBuyPercent(100)}
                >
                  100%
                </span>
              </div>
            </div>
            <div className="field f-cb">
              {orderMethod === "market" ? (
                ""
              ) : (
                <>
                  <label className="ng-binding">{t("Total")}: </label>
                  <div className="iptwrap">
                    <input
                      type="number"
                      step="any"
                      id="total_buy"
                      onChange={handleBuyChange}
                      name="buy_order_total"
                      value={buy_order_total}
                      disabled
                      className="ng-pristine ng-untouched ng-valid ng-empty"
                    />
                    <span className="unit ng-binding">{secondCoin}</span>
                  </div>
                </>
              )}
              <div className="cls"></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  {t("Trade Fee")} :{" "}
                  {buy_commission == null
                    ? 0
                    : Number.isInteger(buy_commission)
                      ? buy_commission
                      : removeTrailingZerosT(
                        parseFloat(buy_commission).toFixed(4)
                      )}
                  {buy_commission != null && <span>%</span>}
                </p>
                <p style={{ fontSize: 10 }} className="ng-binding">
                  {t("Net Amount")} : {feeAmyt && feeAmyt != 'NaN' ? parseFloat(feeAmyt).toFixed(8) : 0}
                </p>
              </div>
            </div>
            {AuthAction()}
          </div>
        </form>
      </div>
    );
  };
  return <div>{buyBoxHtml()}</div>;
}

export default BuyBox;
