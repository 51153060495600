import React from "react";
import { Link } from "react-router-dom";
import { Component } from "react";
import { withTranslation } from 'react-i18next';
import * as myConstList from "../Common/BaseUrl";
import DocumentMeta from "react-document-meta";
import axios from "axios";
import LoginHeader from "../Common/LoginHeader";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ProductName } from "../Common/ProductName";
import { ToastContainer, toast } from "react-toastify";
const baseUrl = myConstList.baseUrl;

class transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainWallet: 1,
      spotTab: "",
      usdcTabClass: "",
      mrcTabClass: "",
      BTC: false,
      INR: false,
      USDC: false,
      MRC: false,
      coinInrListData: [],
      coinDefultListData: [],
      coinMrcListData: [],
      allData: [],
      showData: [],
      totalBalance: 0,
      defaultValue: "",
      selectedCoin: "",
    };
  }

  componentWillMount() {
    if (!localStorage.id) {
      window.location.href = "/login-to-buy-sell-crypto";
    }

    this.trasactionList();
    this.WalletAllCoinBalance();
  }

  WalletAllCoinBalance = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
      .then((resp) => {
        var resp = resp.data;
        this.setState({ Status: resp.status, tableData1: resp.data });
      });
  };

  trasactionList = () => {
    let headerData = {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
    axios
      .post(baseUrl + "/frontapi/transaction-list", {}, headerData)
      .then((resp1) => {
        var resp = resp1.data;
        if (resp.status) {
          this.setState({
            allData: resp.data,
            TransactionsStatus: resp.status,
          });
          this.OpenMain(resp.data[0]);
        }
      });
  };

  OpenMain = (value) => {
    this.setState({
      INR: false,
      MRC: false,
      BTC: true,
      USDC: false,
      mainWallet: value.coin_id,
      spotTab: "",
      showData: value,
    });
  };

  OpenSpot = (value) => {
    this.setState({
      INR: true,
      MRC: false,
      BTC: false,
      USDC: false,
      mainWallet: "",
      spotTab: "active",
      showData: "inrTable",
    });
  };

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ selectedCoin: value });
  };

  showTableHtml = (value) => {
    const { t } = this.props;
    //console.log(value);
    if (
      this.state.TransactionsStatus === true &&
      Object.keys(this.state.showData).length > 0
    ) {
      const html = [];
      let balance = 0;
      let WalletBAlnace = 0;
      if (value.walletSum != undefined) {
        WalletBAlnace = value.walletSum[0].walletAmount;

        if (value.wallet[value.wallet.length - 1] != undefined) {
          balance = value.wallet[value.wallet.length - 1].coin_amount;
        }
      }
      let mainBalance = 0;
      const filteredTransactions =
        value &&
        value.wallet &&
        value.wallet.filter((e) => e.coinName === this.state.selectedCoin);
      if (filteredTransactions.length > 0) {
        filteredTransactions.forEach((e, i) => {
         // console.log(e)
          if (i == value.wallet.length - 1) {
            mainBalance = balance;
          } else {
            if (i > 0) {
              mainBalance = mainBalance + e.coin_amount;
            } else {
              mainBalance = balance + e.coin_amount;
            }
          }
          const truncatedHash = e.tx_id ? e.tx_id.slice(0, 8) + ".." : "";
          html.push(
            <tr>
              <td>{i + 1}</td>
              <td>
                {e.trans_type === "deposit"
                  ? t("Deposit")
                  : e.tx_type == "Wallet to transactions"
                  ? t("Wallet to Spot")
                  : e.tx_type == "purchase"
                  ? t("Deposit")
                  : e.tx_type == "withdrawal"
                  ? t("Withdrawal")
                  : e.tx_type}
              </td>
              <td>
                {e.coin_amount - (e.coin_amount * e.admin_commission) / 100}
              </td>
              <td>{WalletBAlnace}</td>
              <td className="ip_width">
                {truncatedHash ? truncatedHash : "-"}{" "}
                {truncatedHash && (
                  <i
                    className="fa fa-copy cursor-pointer"
                    onClick={() => this.copyCodeToClipboard(e.tx_id)}
                  ></i>
                )}
              </td>
              <td>{e.coinName}</td>
              <td className="td_date">{Moment(e.created).format("lll")}</td>
              {/*console.log("StatusStatus", e.status)*/}
              {/* <td>{(e.status == "completed" && e.WithdrawlRequest == "1") ? t("Completed") : t("Pending")}</td> */}
              <td>{t(e.status)}</td>
            </tr>
          );
          WalletBAlnace = parseFloat(WalletBAlnace - e.coin_amount).toFixed(8);
        });
      } else if (!this.state.selectedCoin) {
        value &&
          value.wallet &&
          value.wallet.forEach((e, i) => {
            if (i == value.wallet.length - 1) {
              mainBalance = balance;
            } else {
              if (i > 0) {
                mainBalance = mainBalance + e.coin_amount;
              } else {
                mainBalance = balance + e.coin_amount;
              }
            }
            const truncatedHash = e.tx_id ? e.tx_id.slice(0, 8) + ".." : "";
            html.push(
              <tr>
                <td>{i + 1}</td>
                <td>
                  {e.trans_type === "deposit"
                    ? t("Deposit")
                    : e.tx_type == "Wallet to transactions"
                    ? t("Wallet to Spot")
                    : e.tx_type == "purchase"
                    ? t("Deposit")
                    : e.tx_type == "withdrawal"
                    ? t("Withdrawal")
                    : e.tx_type}
                </td>
                <td>
                  {e.coin_amount - (e.coin_amount * e.admin_commission) / 100}
                </td>
                <td>{WalletBAlnace}</td>
                <td className="ip_width">
                  {truncatedHash ? truncatedHash : "-"}{" "}
                  {truncatedHash && (
                    <i
                      className="fa fa-copy cursor-pointer"
                      onClick={() => this.copyCodeToClipboard(e.tx_id)}
                    ></i>
                  )}
                </td>
                <td>{e.coinName}</td>
                <td className="td_date">{Moment(e.created).format("lll")}</td>
                {/* {console.log("StatusStatus", e.status)} */}
                {/* <td>{e.status == "completed" && e.WithdrawlRequest == "1" ? t("Completed") : t("Pending")}</td> */}
                <td>{t(e.status)}</td>
              </tr>
            );
            WalletBAlnace = parseFloat(WalletBAlnace - e.coin_amount).toFixed(
              8
            );
          });
      } else {
        return (
          <tr>
            <td colSpan={8} className="text-center">
              No Data Found
            </td>
          </tr>
        );
      }

      return <tbody className="">{html}</tbody>;
    } else {
      return (
        <tr>
          <td colSpan={8} className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };

  copyCodeToClipboard = async (tx_id) => {
    try {
      await navigator.clipboard.writeText(tx_id);
      toast.success("Copy successfully");
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
    }
  };

  render() {
    const { t } = this.props;
    const meta = {
      title: `Transactions | ${ProductName}  Exchange`,
      description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { allData, selectedCoin } = this.state;
    return (
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="">
            <div className="bg_light">
              <div className="new_inpage">
                <div className="container container2">
                  {/* <LeftTab /> */}
                  <div className="white_box">
                    <div className="row">
                      <div className="col-md-8 col-lg-9">
                        <ul className="nav nav-tabs2 mb-3 ">
                          <li className="nav-item">
                            <Link to="/transactions" className="active">
                              {" "}
                              {t("Main wallet")}{" "}
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/depositeHistory"> {t("Spot")} </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/coinDeposite"> {t("Deposit")} {t("Coin")} </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/coinFundRequest"> {t("Withdrawal")} </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 col-lg-3">
                        <select
                          className="form-control"
                          value={selectedCoin}
                          onChange={this.handleChange}
                        >
                          <option value={""}>{t("Select Crypto")}</option>
                          {allData.length > 0 &&
                            allData[0].coinList.map((item) => {
                              return (
                                <option value={item.short_name}>
                                  {item.short_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="tab-content">
                      <div className="tab-pane active" id="Balance">
                        <div className="top_balance"></div>
                        <div className="rows">
                          <div className="wallet_box">
                            <div className="table-responsive">
                              <div className="scrool_wallet_box">
                                <table className="table table-striped mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>{t("Sr. No.")}</th>
                                      <th>{t("Transaction Type")}</th>
                                      <th>{t("Amount")}</th>
                                      <th>{t("Final Balance")}</th>
                                      <th className="ip_width">
                                        {t("Transaction ID")}
                                      </th>
                                      <th>{t("Currency Type")} </th>
                                      <th>{t("Created At")}</th>
                                      <th>{t("Status")}</th>
                                    </tr>
                                  </thead>
                                  {this.showTableHtml(
                                    this.state.showData || []
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </DocumentMeta>
    );
  }
}

export default withTranslation()(transactions);
