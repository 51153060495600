import React from 'react'
import { ToastContainer } from 'react-toastify'
import WhiteList from './Whitelist'
import LoginHeader from "../Common/LoginHeader"
import LeftTab from '../Common/LeftTab'

function index() {
  return (
    <>
      <LoginHeader id={"allow"} />
      {/* <LeftTab/> */}
      <WhiteList />
      <ToastContainer limit={1} />
    </>
  )
}

export default index