import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Moment from "moment";
import socketIOClient from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams, useHistory } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { baseUrl, socketUrl } from "./Common/BaseUrl";
import LoginHeader from "./Common/LoginHeader";
import DocumentMeta from "react-document-meta";
import { ProductName } from "./Common/ProductName";


import { useTranslation } from "react-i18next";
// import { stat } from "fs";

let interId = null;

const CoinExchange = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const params = useParams();
    const clientRef = useRef(null);
    const [state, setState] = useState({ coin_one: "", coin_two: 46, qty: 0, coin_qty: 0, total_qty: 0, available_coin: 0, total_amount: 0, coin_name: null, token_address: "" });
    // const [state, setCoin] = useState({ coin_one: "", coin_two: 46, qty: 0, coin_qty: 0, total_qty: 0, available_coin: 0, total_amount: 0, coin_current_price: 0, coin_name: null });

    const [selectedCoin, setSelectedCoin] = useState({ name: "" });
    const [selectedUpcomingCoin, setSelectedUpcomingCoin] = useState({ name: "" });

    const [coinList, setCoinList] = useState([]);
    const [userCoins, setUserCoins] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [coinPairs, setCoinPairs] = useState([]);
    const [tokenList, setTokenList] = useState({ current: [], upcoming: [] });

    const [userQty, setUserQty] = useState("");
    const [buttonDisable, setButtonDisable] = useState(0);
    const [errorState, setErrorState] = useState({ coin_select: "", quantity: "" });

    const binanceWebsocket = useRef(null);
    const [refresh, setRefresh] = useState(false);
    const [coinListData, setCoinListData] = useState([]);
    const [setUrl, setSetUrl] = useState("");
    const [buyAmount, setBuyAmount] = useState("");
    const [sellAmount, setSellAmount] = useState("");
    const [sell_order_total, setSell_order_total] = useState("");
    const [buy_order_total, setBuy_order_total] = useState("");
    const [setActiveTab, setSetActiveTab] = useState("USDT");
    const [exchange_tab, setExchange_tab] = useState([]);
    const [exchange, setExchange] = useState("");
    const [searchedValue, setSearchedValue] = useState("");
    const [onPriceChange, setOnPriceChange] = useState(true);
    const [onChangeChange, setOnChangeChange] = useState(true);
    const [coinListStatus, setCoinListStatus] = useState(false);
    const [firstCoin, setFirstCoin] = useState("");
    const [secondCoin, setSecondCoin] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [activeBuyOrder, setActiveBuyOrder] = useState(false);
    const [activeBuyOrderData, setActiveBuyOrderData] = useState([]);
    const [activeSellOrder, setActiveSellOrder] = useState(false);
    const [activeSellOrderData, setActiveSellOrderData] = useState([]);
    const [livPrice, setLivPrice] = useState("0:00");
    const [binanceTicker, setBinanceTicker] = useState([]);
    const [topBarStatus, setTopBarStatus] = useState(false);
    const [binanceTrades, setBinanceTrades] = useState([]);
    const [binanceDepth, setBinanceDepth] = useState([]);
    const [currentPrice, setCurrentPrice] = useState("");
    const [marketTrade, setMarketTrade] = useState(false);
    const [marketTradeData, setMarketTradeData] = useState([]);
    const [topBarApiData, setTopBarApiData] = useState([]);
    const [myActiveBuyOrder, setMyActiveBuyOrder] = useState(false);
    const [myActiveBuyOrderData, setMyActiveBuyOrderData] = useState([]);
    const [myActiveSellOrder, setMyActiveSellOrder] = useState(false);
    const [myActiveSellOrderData, setMyActiveSellOrderData] = useState([]);
    const [myCompletedBuyOrder, setMyCompletedBuyOrder] = useState(false);
    const [myCompletedBuyOrderData, setMyCompletedBuyOrderData] = useState([]);
    const [myCompletedSellOrder, setMyCompletedSellOrder] = useState(false);
    const [myCompletedSellOrderData, setMyCompletedSellOrderData] = useState([]);
    const [firstCoinBalance, setFirstCoinBalance] = useState(0);
    const [secondCoinBalance, setSecondCoinBalance] = useState(0);
    const [mySpotBalance, setMySpotBalance] = useState([]);
    const [portfolioData, setPortfolioData] = useState("");
    const [key, setKey] = useState('current');
    const token = localStorage.getItem("token");
    const streams = ["@ticker", "@depth20", "@trade"];
    const [SKey, setSKey] = useState("");

    useEffect(() => {
        getList()
        // walletAllCoinBalance();
        getTransactionList();
        getData()
        //getCoinPriceList();
    }, [])

    useEffect(() => {
        console.log(state.coin_name, " = ", interId)
        if (state.coin_name) {
            if (interId) {
                clearInterval(interId)
            }
            if (state.coin_name != "USDT") {
                console.log("cronstyart");
                interId = setInterval(() => { getCoinPriceList(state.coin_name) }, 5000);
            }
            //getCoinPriceList(state.coin_name)
        }
    }, [state.coin_name])

    console.log("userfeect");

    const getData = () => {
        axios.post(baseUrl + "/frontapi/sale_market_token").then((res) => {
            if (res.data.status) {
                setTokenList(res.data.data)
                setSKey(res.data.data.wallet_address)
            }
        }).catch((err) => {

        })
    }

    const sortCoin = (type) => {
        if (type === "price") {
            setOnPriceChange(!onPriceChange);
        }
        if (type === "change") {
            setOnChangeChange(!onChangeChange);
        }
    };

    const activeCurrency = (value) => {
        console.log("val=> ", value);
        localStorage.setItem("setUrl", value);
        setSetUrl(value);
        setBuyAmount("");
        setSellAmount("");
        setSell_order_total(0);
        setBuy_order_total(0);
        setSetActiveTab(value);
    };


    const onSearch = (event) => {
        setSearchedValue(event.target.value);
    };

    const EmptySearch = () => {
        setSearchedValue("");
    };

    const coinListHtml_M = (coinListData) => {
        try {
            // console.log("coinListData => ", coinListData);
            var listData = [];
            if (coinListStatus === true && coinListData.length > 0) {
                listData = coinListData;
            }
            var filteredItems = listData.filter((item) =>
                item.first_coin_name.includes(searchedValue.toUpperCase())
            );

            let tableHtml = filteredItems.map((item, index) => {
                var percentShow =
                    ((parseFloat(item.current_price) -
                        parseFloat(item.oneday_before_price)) *
                        100) /
                    item.oneday_before_price;
                percentShow = percentShow.toFixed(2);
                var showActive =
                    firstCoin === item.first_coin_name &&
                    secondCoin === item.second_coin_name;

                var lastPrice = parseFloat(item.current_price); // Get the last price from the item object
                var decimalPlaces = lastPrice < 1 ? 8 : 2; // Determine the number of decimal places based on the value of the number
                var formattedPrice = lastPrice.toLocaleString("en-US", {
                    minimumFractionDigits: decimalPlaces,
                    maximumFractionDigits: decimalPlaces,
                });
                formattedPrice = item.current_price == null ? 0 : formattedPrice;
                if (setActiveTab == "fav" && item.activeStatus == "YES") {
                    // console.log("1 setActiveTab =-----> ");
                    return (
                        <tr
                            key={index}
                            className={showActive ? "ng-scope active" : "ng-scope"}
                        >
                            {(screenWidth > 766) ?
                                <Link
                                    to={
                                        "/listing/" +
                                        item.first_coin_name +
                                        "/" +
                                        item.second_coin_name
                                    }
                                    onClick={() =>
                                        dataOnClick(
                                            item.first_coin_name,
                                            item.second_coin_name,
                                            item.isBinance
                                        )
                                    }
                                >
                                    <td className="r-market-pair ng-binding fw600">
                                        {item.icon ? (
                                            <img
                                                className="currency_icon"
                                                src={baseUrl + "/static/currencyImage/" + item.icon}
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                className="currency_icon"
                                                src="/assets/img/btc.png"
                                                alt=""
                                            />
                                        )}
                                        {item.first_coin_name}
                                        <small>/{item.second_coin_name}</small>
                                    </td>
                                    <td className="r-market-price ng-binding fw600">
                                        {item.current_price}
                                    </td>
                                    <td className="r-market-rate ng-scope tr">
                                        <span
                                            className="ng-binding ng-scope green"
                                            style={
                                                percentShow < 0 ? { color: "red" } : { color: "green" }
                                            }
                                        >
                                            {isNaN(percentShow) ? "0.00" : percentShow}%
                                        </span>
                                    </td>
                                </Link>
                                :
                                <a onClick={() => clickCurrency(item)}>

                                    <td className="r-market-pair ng-binding fw600">
                                        {item.icon ? (
                                            <img
                                                className="currency_icon"
                                                src={baseUrl + "/static/currencyImage/" + item.icon}
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                className="currency_icon"
                                                src="/assets/img/btc.png"
                                                alt=""
                                            />
                                        )}
                                        {item.first_coin_name}
                                        <small>/{item.second_coin_name}</small>
                                    </td>
                                    <td className="r-market-price ng-binding fw600">
                                        {item.current_price}
                                    </td>
                                    <td className="r-market-rate ng-scope tr">
                                        <span
                                            className="ng-binding ng-scope green"
                                            style={
                                                percentShow < 0 ? { color: "red" } : { color: "green" }
                                            }
                                        >
                                            {isNaN(percentShow) ? "0.00" : percentShow}%
                                        </span>
                                    </td>
                                </a>}
                        </tr>
                    );
                }
                if (item.second_coin_name == setActiveTab) {
                    // console.log("2 setActiveTab =-----> ");
                    return (
                        <tr
                            key={index}
                            className={showActive ? "ng-scope active" : "ng-scope"}>

                            <Link
                                to={
                                    "/listing/" +
                                    item.first_coin_name +
                                    "/" +
                                    item.second_coin_name
                                }
                                onClick={() =>
                                    dataOnClick(
                                        item.first_coin_name,
                                        item.second_coin_name,
                                        item.isBinance
                                    )
                                }
                            >
                                <td className="r-market-pair ng-binding fw600">
                                    {item.icon ? (
                                        <img
                                            className="currency_icon"
                                            src={baseUrl + "/static/currencyImage/" + item.icon}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            className="currency_icon"
                                            src="/assets/img/btc.png"
                                            alt=""
                                        />
                                    )}
                                    {item.first_coin_name}
                                    <small>/{item.second_coin_name}</small>
                                </td>
                                <td className="r-market-price ng-binding fw600">
                                    {item.current_price}
                                </td>
                                <td className="r-market-rate ng-scope tr">
                                    <span
                                        className="ng-binding ng-scope green"
                                        style={
                                            percentShow < 0 ? { color: "red" } : { color: "green" }
                                        }
                                    >
                                        {isNaN(percentShow) ? "0.00" : percentShow}%
                                    </span>
                                </td>
                            </Link>



                        </tr>
                    );
                }
            });
            //  console.log(tableHtml);
            return tableHtml
        } catch (e) {
            console.log(e);
        }
    };

    const dataOnClick = (firstCoin, secondCoin, isBinance) => {
        console.log("dataOnClick=> ", firstCoin, secondCoin, isBinance);
        if (isBinance === true) {
            if (binanceWebsocket.current) {
                binanceWebsocket.current.close();
            }
            let data = firstCoin + secondCoin;
            let symbol = data.toLowerCase();
            _connectSocketStreams(streams.map((i) => `${symbol}${i}`));
            setExchange("binance");
        } else {
            setExchange("");
            if (binanceWebsocket.current) {
                binanceWebsocket.current.close();
            }
        }

        if (params.firstCoin !== firstCoin) {
            // clientRef.current.disconnect();
            const pairData = {
                firstCoin: firstCoin,
                secondCoin: secondCoin,
            };
            //clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
            // clientRef.current.emit("orderBookData", pairData); // Emit an event to request market data for the pair
            // clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
            clientRef.current.emit("coinPairList");
            handleSocketData(
                clientRef.current,
                clientRef.current.id,
                firstCoin,
                secondCoin
            );
            setRefresh(!refresh);
        }
        localStorage.setItem("tab", setActiveTab);
    };

    const clickCurrency = (value) => {
        console.log("val=> ", value);
        if (screenWidth > 766) {
            console.log("dataOnClick=> ");
            dataOnClick(value.first_coin_name, value.second_coin_name, value.isBinance)

        } else {
            history.push("/exchange-market/" + value.first_coin_name + "/" + value.second_coin_name + "");
        }
    };

    const secondConstructor = async (obj) => {
        return {
            cost: obj.p,
            amount: obj.q,
            timestamp: obj.T,
        };
    };

    const _connectSocketStreams = (streams) => {
        //console.log(streams);
        let newArr = [];
        // Close the previous WebSocket connection
        if (binanceWebsocket.current) {
            binanceWebsocket.current.close();
        }
        try {
            streams = streams.join("/");
            // Create a new WebSocket connection
            binanceWebsocket.current = new WebSocket(
                `wss://stream.binance.com:9443/stream?streams=${streams}`
            );
            binanceWebsocket.current.onmessage = async (evt) => {
                const eventData = JSON.parse(evt.data);
                if (eventData.stream.endsWith("@ticker")) {
                    const obj = eventData.data;
                    setLivPrice(obj.c);
                    setBinanceTicker(obj);
                    setTopBarStatus(true);
                }

                if (eventData.stream.endsWith("@trade")) {
                    const mainValue = eventData.data;
                    const data = await secondConstructor(mainValue);
                    newArr.push(data);
                    if (newArr.length > 10) {
                        newArr.shift();
                    }
                    const binanceTrades = { binanceTrades: newArr };
                    const trades = binanceTrades.binanceTrades;
                    setBinanceTrades(trades);
                }

                if (eventData.stream.endsWith("@depth20")) {
                    setBinanceDepth(eventData.data);
                }
            };

            binanceWebsocket.current.onerror = (evt) => {
                console.error(evt);
            };
        } catch (error) {
            console.log("err2", error);
        }
    };

    const handleSocketData = (socket, id, firstCoin, secondCoin) => {
        console.log("userfeect1244444");
        socket.off(`marketDataPairApi_${firstCoin}_${secondCoin}`);
        socket.on(`marketDataPairApi_${firstCoin}_${secondCoin}`, (socketData) => {
            const { buyOrderData, sellOrderData } = socketData;
            const activeBuyOrderData = buyOrderData;
            const activeSellOrderData = sellOrderData;
            if (activeBuyOrderData.status) {
                setActiveBuyOrder(activeBuyOrderData.status);
                setActiveBuyOrderData(activeBuyOrderData.data);
            } else {
                setActiveBuyOrder(activeBuyOrderData.status);
                setActiveBuyOrderData([]);
            }
            if (activeSellOrderData.status) {
                setActiveSellOrder(activeSellOrderData.status);
                setActiveSellOrderData(activeSellOrderData.data);
            } else {
                setActiveSellOrder(activeSellOrderData.status);
                setActiveSellOrderData([]);
            }
        });

        socket.off(`orderBookDataApi_${firstCoin}_${secondCoin}`);
        socket.on(`orderBookDataApi_${firstCoin}_${secondCoin}`, (orderData) => {
            const { data, status, lastPrice } = orderData;
            setCurrentPrice(lastPrice);
            setMarketTrade(status);
            setMarketTradeData(data);
        });

        socket.off(`topBarDataApi_${firstCoin}_${secondCoin}`);
        socket.on(`topBarDataApi_${firstCoin}_${secondCoin}`, (topBarData) => {
            const { data, status } = topBarData;
            setTopBarApiData(data);
            setTopBarStatus(status);
        });

        socket.off("coinPairListApi");
        socket.on("coinPairListApi", (coinListData) => {
            const { data, status } = coinListData;
            // console.log("new data ",data);
            setCoinListData(data);
            setCoinListStatus(status);
        });
        socket.off(`resultPairBinance_${firstCoin}_${secondCoin}`);
        socket.on(`resultPairBinance_${firstCoin}_${secondCoin}`, (topBarData) => {
            const { data, status } = topBarData;
            if (status === true) {
                setExchange(data.exchange);
            } else {
                setExchange("");
            }
        });
        socket.off("refresh");
        socket.on("refresh", () => {
            const pairData = {
                firstCoin: firstCoin,
                secondCoin: secondCoin,
            };

            clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
            clientRef.current.emit("orderBookData", pairData); // Emit an event to request order book data for the pair
            clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
            clientRef.current.emit("coinPairList");
            clientRef.current.emit("checkPairBinance", pairData); // Emit an event to request top bar data for the pair
            handleSocketData(
                clientRef.current,
                clientRef.current.id,
                firstCoin,
                secondCoin
            );
            setRefresh(!refresh);

            const token = localStorage.getItem("token");
            if (token) {
                subscribeToUserStreams(clientRef.current, firstCoin, secondCoin);
            }
        });
    };

    const subscribeToUserStreams = (socket, firstCoin, secondCoin) => {
        const pairData = {
            firstCoin: firstCoin,
            secondCoin: secondCoin,
        };
        socket.emit("userPendingOrders", pairData);
        socket.emit("userCompleteOrders", pairData);
        socket.emit("userBalanceByPair", pairData);
        socket.emit("mySpotBalance", pairData);
        socket.emit("userRealTimePortfolio", pairData);

        handleUserSocketResp(socket);
    };

    const handleUserSocketResp = (socket) => {
        socket.off("userPendingOrdersApi");
        socket.on("userPendingOrdersApi", (pendingOrders) => {
            const { buyOrderData, sellOrderData } = pendingOrders;

            if (buyOrderData.status) {
                setMyActiveBuyOrder(buyOrderData.status);
                setMyActiveBuyOrderData(buyOrderData.data);
            } else {
                setMyActiveBuyOrder(buyOrderData.status);
                setMyActiveBuyOrderData([]);
            }
            if (sellOrderData.status) {
                setMyActiveSellOrder(sellOrderData.status);
                setMyActiveSellOrderData(sellOrderData.data);
            } else {
                setMyActiveSellOrder(sellOrderData.status);
                setMyActiveSellOrderData([]);
            }
        });

        socket.off("userCompleteOrdersApi");
        socket.on("userCompleteOrdersApi", (completedOrders) => {
            const { buyOrderData, sellOrderData } = completedOrders;

            if (buyOrderData.status) {
                setMyCompletedBuyOrder(buyOrderData.status);
                setMyCompletedBuyOrderData(buyOrderData.data);
            } else {
                setMyCompletedBuyOrder(buyOrderData.status);
                setMyCompletedBuyOrderData([]);
            }
            if (sellOrderData.status) {
                setMyCompletedSellOrder(sellOrderData.status);
                setMyCompletedSellOrderData(sellOrderData.data);
            } else {
                setMyCompletedSellOrder(sellOrderData.status);
                setMyCompletedSellOrderData([]);
            }
        });

        socket.off("userBalanceByPairApi");
        socket.on("userBalanceByPairApi", (userBalanceByPairApi) => {
            const { firstCoinBalance, secondCoinBalance } = userBalanceByPairApi;
            setFirstCoinBalance(firstCoinBalance);
            setSecondCoinBalance(secondCoinBalance);
        });

        socket.off("mySpotBalanceApi");
        socket.on("mySpotBalanceApi", (mySpotBalanceApi) => {
            setMySpotBalance(mySpotBalanceApi);
        });

        socket.off("userRealTimePortfolioApi");
        socket.on("userRealTimePortfolioApi", (portfolioBalc) => {
            setPortfolioData(portfolioBalc);
        });
    };

    const selectCoin = (val) => {
        console.log(val);
        let newdate = new Date();
        if (val.date_of_live_str < newdate.getTime() / 1000) {
            val.lastPrice = val.price;
            setSelectedCoin(val)
            setErrorState({ coin_select: "", quantity: "" })
        } else {
            setSelectedCoin({ name: "" });
            setSelectedUpcomingCoin(val)
            toast.success(t("Launching Soon"));
        }
    }


    const handleChange = (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        if (e.target.name == "coin_one") {
            let avail_coin = 0;
            let totalQty = 0;
            let coinCurrentPrice = 0;
            let totalAmount = 0;
            let coin_name = null;
            let sValue = coinList.find((val) => val.currency_one_id == 7);
            console.log("sValue ", sValue);
            let sCoinDetail = coinList.find((val) => val.currency_one_id == e.target.value);
            coin_name = sCoinDetail.first_coin_sort
            if (e.target.value != 7) {

                //  console.log("sCoinDetail ", sCoinDetail);
                if (sCoinDetail) {
                    //let setCoinDetail  = 


                    // getCoinPriceList(sCoinDetail.first_coin_sort)
                    // console.log(sCoinDetail.first_coin_sort);
                    let coinDetail = userCoins.find((val) => val.coin_id == e.target.value);
                    // console.log("cd ", coinDetail.getValue.main[0].coin_amount);
                    if (coinDetail.getValue.main[0].coin_amount != null) {
                        avail_coin = coinDetail.getValue.main[0].coin_amount;
                    }
                    // console.log("available_coin ", avail_coin);
                    totalQty = sValue.qty_two * state.qty;
                    let pairPrice = coinPairs.find((val) => val.symbol == sCoinDetail.first_coin_sort + "USDT");
                    if (pairPrice) {
                        coinCurrentPrice = pairPrice.lastPrice;
                        // console.log(pairPrice.lastPrice);
                        sCoinDetail.lastPrice = pairPrice.lastPrice;
                    }
                    setSelectedCoin(sCoinDetail)
                }
                totalAmount = totalQty * coinCurrentPrice;
            } else {
                totalQty = sValue.qty_two * state.qty;
                totalAmount = totalQty;
                setSelectedCoin({ lastPrice: 1 })
            }


            // console.log("coin_name ", coin_name);
            setState({ ...state, [e.target.name]: e.target.value, coin_qty: sValue.qty_two, available_coin: avail_coin, total_qty: totalQty, total_amount: totalAmount, coin_current_price: coinCurrentPrice, coin_name: coin_name, coin_one: e.target.value })

        } else if (e.target.name == "qty") {

            console.log(selectedCoin);
            // if (e.target.value != "") {
            let totalQty = state.coin_qty * e.target.value;
            let lastPrice = (selectedCoin.price) ? selectedCoin.price : 0
            let totalAmount = totalQty * lastPrice;
            // console.log(e.target.value);
            // if (e.target.value <= state.available_coin) {
            //  console.log("yes");
            setState({ ...state, [e.target.name]: e.target.value, total_qty: totalQty, total_amount: totalAmount })
            setUserQty(e.target.value)
            if (e.target.value != "") {
                setErrorState({ ...errorState, quantity: "" })
            }
            // }else{
            //     console.log("else");
            //     e.preventDefault()
            // }
            // } else {
            // setState({ ...state, [e.target.name]: "" })
            //}
        } else if (e.target.name == "token_address") {
            setState({ ...state, [e.target.name]: e.target.value })
            if(e.target.value) {
                setErrorState({ ...errorState, token_address: "" })
            }
        }
    }

    const walletAllCoinBalance = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        axios
            .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
            .then((res) => {
                //  console.log(res);
                if (res.data.status) {
                    setUserCoins(res.data.data)
                }
            });
    };

    const getList = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let sendData = {};
        axios.post(baseUrl + "/frontapi/token_exchange_list", sendData, config).then((res) => {
            //console.log(res);
            if (res.data.status) {
                setCoinList(res.data.data)               
               
            }
        })
    }

    const getCoinPriceList = (coinName) => {
        console.log(coinName, " = ", interId);
        //let temp = {...state}
        if (coinName) {
            // let temp = {...selectedCoin}
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            axios.get(baseUrl + "/api/binance-pair-data", config).then((res) => {
                // console.log(res.data.data.body);
                setCoinPairs(res.data.data.body);

                let pairPrice = res.data.data.body.find((val) => val.symbol == coinName + "USDT");
                //  console.log(pairPrice);
                if (pairPrice) {
                    let coinCurrentPrice = pairPrice.lastPrice;
                    setSelectedCoin({ ...selectedCoin, lastPrice: coinCurrentPrice })

                    let totalQty = state.coin_qty * (userQty != "" ? userQty : 0);
                    let totalAmount = totalQty * coinCurrentPrice;
                    // console.log(e.target.value);
                    // if (e.target.value <= state.available_coin) {
                    // console.log("yes", state);
                    setState({ ...state, total_amount: totalAmount })
                    // setState({ ...state, coin_current_price: coinCurrentPrice })
                }


            })
            //  setTimeout(() => { getCoinPriceList(selectedCoin) }, 5000);
        }

    }

    // setInterval(() => { getCoinPriceList() }, 5000);

    const getTransactionList = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let sendData = {};
        axios.post(baseUrl + "/frontapi/token_exchange_transactions", sendData, config).then((res) => {
            //console.log(res);
            if (res.data.status) {
                setTransactions(res.data.data)
            }
        })
    }

    const keyCopy = () => {
        //console.log("click ", SKey);
        navigator.clipboard.writeText(SKey)
        toast.success(t("Copied to clipboard"));
      }

    const submit = () => {
        console.log(state)
        console.log(selectedCoin)
        let flag = 1;
        let error = {};
        if (!selectedCoin.id) {
            flag = 0;
            console.log("enert");
            error = { ...error, coin_select: "Please Select Coin" };
        }
        if (state.token_address == "") {
            flag = 0;
            error = { ...error, token_address: "Enter Your transaction Hash" };
        } else if (state.token_address.length < 10) {
            flag = 0;
            error = { ...error, token_address: "Please Enter valid transaction address" };
        }
        if (userQty == "" || userQty == "0") {
            flag = 0;
            error = { ...error, quantity: "Please enter Quantity" };
        }
        else if (userQty < "0") {
            flag = 0;
            error = { ...error, quantity: "Please enter Valid Quantity" };
        }
        // else if (userQty > selectedCoin.user_limit) {
        //     flag = 0;
        //     error = { ...error, quantity: "Entered Quantity not available" };
        // }
        console.log(error);
        setErrorState(error)

        if (flag) {
            clearInterval(interId)
            setButtonDisable(1)
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let sendData = state;
            sendData.qty = userQty;
            sendData.coin_one = selectedCoin.id;
            sendData.total_amount = selectedCoin?.price * userQty;
            //state?.coin_qty * userQty * selectedCoin?.lastPrice
            console.log(sendData)
            axios.post(baseUrl + "/frontapi/userCoinExchangeBuy", sendData, config).then((res) => {
                console.log("res=> ", res);
                if (res.data.status) {
                    toast.success(t(res.data.message));
                    setUserQty("")
                    setState({ coin_one: "", coin_two: 46, qty: 0, coin_qty: 0, total_qty: 0, available_coin: 0, total_amount: 0, coin_name: null, token_address: "" })
                    setSelectedCoin({ name: "" })
                    setButtonDisable(0)
                    // window.location.reload();
                    getTransactionList()
                    walletAllCoinBalance();
                } else {
                    toast.error(res.data.message);
                }
            }).catch((e) => {
                console.log("error=> ", e);
            })
        }

    }

    const showTableHtml = (value) => {

        // console.log(value);

        let senddata = [];

        if (value.length == 0) {

        } else {
            value.forEach((e, i) => {
                senddata.push(
                    <tr>
                        <td className="">
                            {i + 1}
                        </td>
                        <td className="">
                            {(e.coin_name != "Binance") ? e.coin_name : "BNB"}
                        </td>
                        <td className="">
                            {e.coin_amount}
                        </td>
                        <td className="">
                            {e.tx_id}
                        </td>
                        <td className="">
                            {e.rejection_reasion}
                        </td>
                        
                        <td className="">
                            {Moment(e.created).format("lll")}
                        </td>
                        <td className="">
                            {t(e.status)}
                        </td>
                    </tr>)
            })
        }

        return <tbody className="">{senddata}</tbody>
    }

    const meta = {
        title: `coinExchange | ${ProductName}  Exchange`,
        description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
        meta: {
            charset: "utf-8",
            name: {
                keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
            },
        },
    };
    const selectOption = (val) => {
        setKey(val);
        unSelectToken();
    }
    const unSelectToken = () => {
        setSelectedCoin({ name: "" })
        setSelectedUpcomingCoin({ name: "" })
        setUserQty("")
    }

    return (
        <DocumentMeta {...meta}>
            <div>
                <LoginHeader />
                <div className="">
                    <div className="bg_light">
                        <div className="new_inpage">
                            <div className="container container2">
                                {/* <LeftTab /> */}
                                <div className="white_box dashbord_in dashbord_in_top fs12 mb-4">

                                    <div className="row">
                                        <div className="col-md-8 col-lg-8">
                                            <div className="coin_tab mb-4">
                                                <p className={"default-token " + (key == "current" ? "selected-token" : "")} onClick={() => {selectOption("current")}}>{t("Current")}</p>

                                                <p className={"default-token " + (key == "upcoming" ? "selected-token" : "")} onClick={(k) => {selectOption("upcoming")}}>{t("Upcoming")}</p>
                                            </div>
                                            {(key == "current") ?
                                                <div className="row">
                                                    {(selectedCoin.name == "") ?
                                                        tokenList.current.map((val, index) =>
                                                            <div className="col-md-3 col-xs-6 coin_in_box">
                                                                <div className={"card cursor-pointer h100" + (val.id == selectedCoin.id ? "selected-token" : "")} onClick={() => selectCoin(val)}>
                                                                    <div className="" role="tab">
                                                                        <div className="img_coin" role="tab">
                                                                            <img className="img-fluid" src={baseUrl + "/static/market_token/" + val.symbol} />
                                                                        </div>
                                                                        <div>{val.name}</div>
                                                                        <div>{t("Price")}: {val.price}</div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        <div className="col-md-12 col-xs-12 coin_in_box">
                                                            <span className="mb-2 cursor-pointer d-inline-block" onClick={() => unSelectToken()}> <i className="fa fa-arrow-left"></i></span>
                                                            <div className={"card"}>
                                                                <div className="p-1" role="tab">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-md-4 ">
                                                                            <img className="img-fluid rd10" src={baseUrl + "/static/market_token/" + selectedCoin.symbol} />
                                                                        </div>
                                                                        <div className="col-md-8 h6">
                                                                            <div className="mb-2"><span class="wcoin_t">{t("Token Name")}: </span>{selectedCoin.name}</div>
                                                                            <div className="mb-2"><span class="wcoin_t">{t("Token Price")}: </span>{selectedCoin.price}</div>
                                                                            <div className="mb-2"><span class="wcoin_t">{t("Per User limit")}:</span> {selectedCoin.user_limit}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className="row">

                                                    {
                                                     (selectedUpcomingCoin.name == "") ?
                                                    
                                                    tokenList.upcoming.map((val, index) =>
                                                        <div className="col-md-3 col-xs-3 coin_in_box">
                                                            <div className="card cursor-pointer h100" onClick={() => selectCoin(val)}>
                                                                <div className="" role="tab" id={"headingOne" + index}>
                                                                    <img className="img-fluid" src={baseUrl + "/static/market_token/" + val.symbol} />
                                                                    <div>{val.name}</div>
                                                                    <div>{t("Price")}: {val.price}</div>
                                                                    

                                                                </div>


                                                            </div>
                                                        </div>
                                                    )
                                                :<div className="col-md-12 col-xs-12 coin_in_box">
                                                <span className="mb-2 cursor-pointer d-inline-block" onClick={() => unSelectToken()}> <i className="fa fa-arrow-left"></i></span>
                                                <div className={"card"}>
                                                    <div className="p-1" role="tab">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-4 ">
                                                                <img className="img-fluid rd10" src={baseUrl + "/static/market_token/" + selectedUpcomingCoin.symbol} />
                                                            </div>
                                                            <div className="col-md-8 h6">
                                                                <div className="mb-2"><span class="wcoin_t">{t("Token Name")}: </span>{selectedUpcomingCoin.name}</div>
                                                                <div className="mb-2"><span class="wcoin_t">{t("Token Price")}: </span>{selectedUpcomingCoin.price}</div>
                                                                <div className="mb-2"><span class="wcoin_t">{t("Per User limit")}:</span> {selectedUpcomingCoin.user_limit}</div>
                                                                <div className="mb-2"><span class="wcoin_t">{t("Launching Date")}:</span> {Moment(selectedUpcomingCoin.date_of_live).format("MMM DD yyy")}</div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                                }
                                                </div>

                                            }
                                        </div>
                                        <div className="col-md-4 col-lg-4">
                                            <h5 class="">{t("Coin")} {t("Exchange")}</h5>
                                            <div className="form-group">
                                                <label className="fw-500">{t("Coin")}</label>
                                                <input className="form-control" type="text" name="cname" onChange={handleChange} value={selectedCoin?.name} placeholder={t("Select Token")} disabled ={true}/>
                                                {/* <select
                                                    className="form-control"
                                                    name="coin_one"
                                                    value={state.coin_one}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value={""}>{t("Select Coin")}</option>
                                                    {coinList.map((val) =>
                                                        <option value={val.currency_one_id}>{val.first_coin_sort}</option>
                                                    )}

                                                </select> */}
                                                <span style={{ "color": "red" }}>{t(errorState.coin_select)}</span>
                                                {/* {selectedCoin?.lastPrice} */}
                                            </div>


                                            <div className="form-group">
                                                <label className="fw-500">{t("Quantity")}</label>

                                                <div className=" d-flex mt-3">

                                                    <input className="form-control" type="number" name="qty" onChange={handleChange} disabled={!selectedCoin.quantity} value={userQty} placeholder={t("Enter Quantity")} />

                                                </div>
                                                <span style={{ "color": "red" }}> {t(errorState.quantity)}</span>
                                                <div className=" d-flex mt-3 form-control">

                                                    {(coinList.length > 0) ?
                                                        <>
                                                            <span className="mr-auto">{coinList[0].second_coin_sort}</span>

                                                            <span className="color1">{(selectedCoin?.price ? selectedCoin?.price : 0) * (userQty)}</span></> : ""}

                                                </div>
                                                <div className="d-flex mt-3 form-control">
                                                    <label className="fw-500 mr-auto">{t("Wallet Address")}</label>
                                                  {(SKey != "")?  <span className="color1"> {SKey} <i className="fa fa-copy ml-2 clip-copy" onClick={keyCopy}></i></span>:""}
                                                    
                                                </div>

                                                <div className=" d-flex mt-3">

                                                    <input className="form-control" type="text" name="token_address" onChange={handleChange} disabled={!selectedCoin.quantity} value={state.token_address} placeholder={t("Enter Your Transaction Hash")} />


                                                </div>
                                                <span style={{ "color": "red" }}>{t(errorState.token_address)}</span>
                                            </div>

                                            <div className="form-group">
                                                <button className="btn btn_man" onClick={submit} type="button" disabled={buttonDisable}>{t("submit")}</button>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-8 col-lg-8 padding-right exchange_left">
                                            <Tabs
                                                id="controlled-tab-example"
                                                activeKey={key}
                                                onSelect={(k) => setKey(k)}
                                                className="mb-3"
                                            >
                                                <Tab eventKey="current" title="Current">
                                                    <div className="rows">
                                                        <div className="wallet_box">
                                                            <div className="table-responsive">
                                                                <div className="scrool_wallet_box">
                                                                    <table className="table table-striped mb-0 ">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{t("Symbol")}</th>
                                                                                <th>{t("Name")}</th>
                                                                                <th>{t("Price")}</th>
                                                                                <th>{t("Percentage change")}</th>
                                                                                <th>{t("Down Percentage")}</th>

                                                                            </tr>
                                                                        </thead>

                                                                        {tokenList.current.map((val, i) =>
                                                                            <tr>
                                                                                <td><img src={baseUrl + "/static/market_token/" + val.symbol} style={{ width: "105px" }} /></td>
                                                                                <td>{val.name}</td>
                                                                                <td>{val.price}</td>
                                                                                <td>{i + 1}</td>
                                                                                <td>{i + 1}</td>

                                                                            </tr>

                                                                        )}
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </Tab>
                                                <Tab eventKey="upcoming" title="Upcoming">
                                                <div className="rows">
                                                        <div className="wallet_box">
                                                            <div className="table-responsive">
                                                                <div className="scrool_wallet_box">
                                                                    <table className="table table-striped mb-0 ">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{t("Symbol")}</th>
                                                                                <th>{t("Name")}</th>
                                                                                <th>{t("Price")}</th>
                                                                                <th>{t("Percentage change")}</th>
                                                                                <th>{t("Down Percentage")}</th>

                                                                            </tr>
                                                                        </thead>

                                                                        {tokenList.upcoming.map((val, i) =>
                                                                            <tr>
                                                                                <td><img src={baseUrl + "/static/market_token/" + val.symbol} style={{ width: "105px" }} /></td>
                                                                                <td>{val.name}</td>
                                                                                <td>{val.price}</td>
                                                                                <td>{i + 1}</td>
                                                                                <td>{i + 1}</td>
                                                                            </tr>

                                                                        )}
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Tab>

                                            </Tabs>
                                        </div> */}

                                    </div>
                                </div>
                                <div className="tab-content white_box">
                                    <div className="tab-pane active" id="Balance">
                                        <div className="top_balance"></div>
                                        <div className="rows">
                                            <div className="wallet_box">
                                                <div className="table-responsive">
                                                    <div className="scrool_wallet_box">
                                                        <table className="table table-striped mb-0 ">
                                                            <thead>
                                                                <tr>
                                                                    <th>{t("Sr. No.")}</th>
                                                                    <th>{t("Coin")}</th>
                                                                    <th>{t("Amount")}</th>

                                                                    <th className="ip_width">
                                                                        {t("Transaction ID")}
                                                                    </th>

                                                                    <th>{t("Reject Reason")}</th>
                                                                    <th>{t("Created At")}</th>
                                                                    
                                                                    <th>{t("Status")}</th>
                                                                </tr>
                                                            </thead>
                                                            {showTableHtml(transactions || [])}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </DocumentMeta>
    );
};

export default CoinExchange;
