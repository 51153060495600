import axios from "axios";
import { baseUrl, socketUrl } from "../../src/components/Common/BaseUrl";

const API_URL = "http://localhost:8080/api/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const getUserDetail = () => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
    var resp = res.data;
    if (resp.status === true && resp.data.length == 1) {
      localStorage.setItem("userData", JSON.stringify(resp.data[0]));
      return resp.data[0];
    }
  }).catch((e) => {
    console.log(e);
  })
}

const logout = () => {
  var theme = localStorage.getItem("theme");
  var lang = localStorage.getItem("lang");
  var homeShow = localStorage.getItem("homepopshow");

  localStorage.removeItem("user");
  localStorage.removeItem("userData");
  localStorage.removeItem("token");
  localStorage.removeItem("homepopshow");
  localStorage.removeItem("lang");
  sessionStorage.removeItem("verifyLoginPin");
  

  sessionStorage.setItem("popshow",true);
  localStorage.setItem("theme", theme);
  localStorage.setItem("lang", lang);
  localStorage.setItem("homepopshow", homeShow);
  return
};

export default {
  register,
  login,
  logout,
  getUserDetail
};
