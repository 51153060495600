import { ProductName } from "./ProductName";

export const meta = {
    meta: {
        charset: "utf-8",
        name: {
            keywords:
                `${ProductName} Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
    },
}

