import React from "react";
import { useTranslation } from "react-i18next";
function SpotBalance({mySpotBalance}) {
  const { t } = useTranslation();
  const showSpotBalance = () => {
    if (mySpotBalance.length > 0) {
      const html = [];
      mySpotBalance.length > 0 &&
        mySpotBalance.map(function (value, i) {
          if (
            value.spot[0].coin_amount === null ||
            value.spot[0].coin_amount === "null"
          ) {
            var spotBalace = 0;
          } else {
            spotBalace = value.spot[0].coin_amount;
          }
          if (spotBalace > 0) {
            html.push(
              <tr key={i}>
                <td>{value.name}</td>
                <td>{spotBalace}</td>
              </tr>
            );
          }
        });
      return <tbody className="main">{html}</tbody>;
    }
  };

  return (
    <>
      <div className="col-md-3">
        <div className="ne_box p-0 ">
          <h6 className="h6_head pl-3  ">{t("Spot")} {t("Balance")}</h6>
          <div className="balance-outer">
            <table className="w-100">{showSpotBalance()}</table>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpotBalance;
