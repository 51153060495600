import React from 'react';
import { useTranslation } from "react-i18next";

const Features = () => {
  const { t } = useTranslation();


  return (
    <div className="feature-main-outer">
      <div className="container">
        <div className="section-heading text-center">
          <h2><b>{t("Features In")}</b></h2>
        </div>
        <div className="feature-list-outer">
          <ul>
            <li><img src="/assets/img/feature-img1.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img2.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img3.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img4.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img5.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img6.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img7.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img8.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img9.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img10.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img11.png" alt="" className="img-fluid"/></li>
          </ul>
          <ul>
            <li><img src="/assets/img/feature-img1.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img2.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img3.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img4.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img5.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img6.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img7.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img8.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img9.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img10.png" alt="" className="img-fluid"/></li>
            <li><img src="/assets/img/feature-img11.png" alt="" className="img-fluid"/></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Features