import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const GoogleLoginButton = ({ onLoginSuccess, baseUrl, redirectUrl }) => {
  const { t } = useTranslation();
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const data = {
        token: tokenResponse.code,
      };
      try {
        const userInfo = await axios.post(
          baseUrl + "/frontapi/google-login",
          data
        );
        console.log("userInfo", userInfo.data);
        // Assuming userInfo.data.data is an array and might have one or more items
        if (userInfo.data && userInfo.data.data.length > 0) {
          localStorage.setItem("id", userInfo.data.data[0].id);
          localStorage.setItem("mobile", userInfo.data.data[0].mobile);
          localStorage.setItem("token", userInfo.data.token);
          toast.success("Login Successfully");
          window.location.href = redirectUrl;
        }
      } catch (error) {
        console.error("Login error:", error);
        toast.error("Login failed");
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
    flow: "auth-code",
  });

  return (
    <button
      className="btn w100 btn_border"
      type="submit"
      onClick={() => googleLogin()}
    >
      <img src={"/assets/img/google.svg"} alt="" className="google_icon mr-2" />{" "}
      {t("continue_with_google")}{" "}
    </button>
  );
};

export default GoogleLoginButton;
