import React,{useState,useEffect} from 'react'
import LoginHeader from "../Common/LoginHeader";
import axios from 'axios';
import { baseUrl } from '../Common/BaseUrl';
import { Link } from "react-router-dom";
import ExchangeKycHeader from '../Common/ExchangeHeader';

function Blog() {
    const [refresh, setRefresh] = useState(false)
    const [record, setRecord] = useState([])
    useEffect(() => {
      getData()
     }, [refresh])
      
     const getData=()=>{
      axios.get(baseUrl+"/api/get-blog").then((res)=>{
        if(res.data.status){
          setRecord(res.data.data)
        }
        else{
          setRecord([])
        }
      }).catch((err)=>{
  
      })
     }
     const changeTheme=()=>{
      
     }
  return (
    
    <div className="">
      <ExchangeKycHeader graphColor={changeTheme}  />
      <div className="new_inpage market-news-outer">
      <div className="container">
	  <h2 className="text-center mb-4">Market News</h2>
      <div className="row">
        {record.map((list)=>{
          return (
            <div className="col-md-4">
            <div className="text-center">
               <div className="market-inner ">
                 <div className="img-outer">
                   <img
                     src={baseUrl+"/static/blog/"+list.image}
                     alt=""
                     className="img-fluid"
                   />
                 </div>
                 <div className="content-outer">
                   <h6>{list.title}</h6>
                  
                   <Link to={`/blog-detail/${list.id}`}>Read More</Link>
                 </div>
               </div>
              </div>
              </div>
          )
        })}
        

	
    </div>
  
    </div>
    </div>
    </div>
 
  )
}

export default Blog
