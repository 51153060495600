import React from "react";
import Moment from "moment";
import { useTranslation } from "react-i18next";

function SellOrder({
  myActiveSellOrder,
  myActiveSellOrderData,
  updateSellOrder,
  updateAndDeleteButton,
  deleteOrder,
  myCompletedSellOrder,
  myCompletedSellOrderData,
}) {
  const { t } = useTranslation();
  const myActiveSellOrderHtml = () => {
    if (myActiveSellOrder) {
      var collectHtml = [];
      myActiveSellOrderData.forEach((e, i) => {
        var getTime = Moment(e.created_at).format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {e.per_price.toFixed(8)}
            </td>
            <td className="f-left ng-binding">
              {e.sell_spend_amount.toFixed(8)}
            </td>
            <td className="f-left ng-binding">
              {(e.per_price * e.sell_spend_amount).toFixed(4)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              {!e.binance_order_id && (
                <button
                  className="btn  mr-2"
                  onClick={() => updateSellOrder(e.sell_id, "sell")}
                  disabled={updateAndDeleteButton}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn "
                onClick={() => deleteOrder(e.sell_id, "sell")}
                disabled={updateAndDeleteButton}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        );
      });
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedSellOrderHtml = () => {
    if (myCompletedSellOrder) {
      var collectHtml = [];
      for (var i = 0; i < 10; i++) {
        var myCompletedSellOrderSingle = myCompletedSellOrderData[i];
        if (myCompletedSellOrderSingle) {
          var getTime = Moment(myCompletedSellOrderSingle.created_at).format(
            "lll"
          );

          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.per_price.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {myCompletedSellOrderSingle.total_sell_spend_amount.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {(
                  myCompletedSellOrderSingle.per_price *
                  myCompletedSellOrderSingle.total_sell_spend_amount
                ).toFixed(8)}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  return (
    <>
      <div className="tab-pane " id="Selltab" role="tabpanel">
        <div className="table_hadding flex-wrap table_hadding2">
          <h6 className="h6_head">{t("Sell Orders")}</h6>
          <div className="status-outer">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#pending-sell"
                  role="tab"
                >
                  {t("Pending")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#complete-sell"
                  role="tab"
                >
                  {t("Completed")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="sell-order-outer tab-content">
          <div className="tab-pane active" id="pending-sell" role="tabpanel">
            <div className="userRecords-main obo_exchange">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th className="f-left ng-binding">{t("Pair")}</th>
                    <th className="f-left ng-binding">{t("Price")}</th>
                    <th className="f-left ng-binding">{t("Amount")}</th>
                    <th className="f-left ng-binding">{t("Total")}</th>
                    <th className="f-left ng-binding">{t("Date & Time")}</th>
                    <th className="f-center cancels">
                      <span className="ng-binding p-0">{t("Actions")}</span>
                    </th>
                  </tr>
                </thead>
                {myActiveSellOrderHtml()}
              </table>
            </div>
          </div>
          <div className="tab-pane" id="complete-sell" role="tabpanel">
            <div className="userRecords-main">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>{t("Pair")}</th>
                    <th>{t("Price")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Total")}</th>
                    <th>{t("Date")}</th>
                  </tr>
                </thead>
                {myCompletedSellOrderHtml()}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellOrder;
