import LoginHeader from "./Common/LoginHeader";
import Footer from "./Common/Footer";
import { Component } from "react";
import * as myConstList from "./Common/BaseUrl";
import axios from "axios";
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import DocumentMeta from "react-document-meta";
import { ProductName } from "./Common/ProductName";

const baseUrl = myConstList.baseUrl;

export class chatting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      method: "",
      location: "",
      tabledata1: [],
      id: "",
      USER_ID: "",
      tichekId: this.props.match.params.coin,
      respStatus: "",
      ResponceData: "",
      issueImageSecond: "",
      SubSetx: "",
      OpenSteps: "Send_Message",
      OpenStepsAll: "all",
      events: [],
      dateTime: "",
      chatValue: "",
      tabledata: [],
      btnDisable: false,
      queryIssue: "",
      commonDate: "",
      issueDateTime: "",
      userUploadImage: "",
    };

    this.logEvent = this.logEvent.bind(this);
    this.clearEvents = this.clearEvents.bind(this);

    if (/^-?[\d.]+(?:e-?\d+)?$/.test(this.state.tichekId) == true) {
      var encodes = btoa(this.state.tichekId);
      this.setState(
        {
          decodes: atob(encodes),
        },
        () => {
          this.TicketList();
          this.ticketchat();
        }
      );
      window.location.replace(`Chatting/${encodes}`);
    } else {
      this.TicketList();
      this.ticketchat();
    }
  }

  ticketchat = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    var decodes = atob(this.state.tichekId);
    var userIdData = { tichekId: decodes };
    if (
      this.props.match.params.status == "1" ||
      this.props.match.params.status == 1
    ) {
      axios
        .post(baseUrl + "/frontapi/seenStatusChange", userIdData, config)
        .then((res) => {
          const resp = res.data;
        });
    }

    axios
      .post(baseUrl + "/frontapi/ticket-chat", userIdData, config)
      .then((res) => {
        const resp = res.data;
        if (resp.status === true) {
          this.setState({
            tabledata: resp.data[0].messages,
            commonDate: resp.data[0].commonDate,
            respStatus: resp.status,
          });
        }

        if (resp.success === true) {
          this.setState({
            respStatus: resp.success,
            username: "",
            email: "",
            password: "",
            cpassword: "",
            check1: "",
            check2: "",
          });
        }
      });
  };

  logEvent = (event) => {
    event.timestamp = new Intl.DateTimeFormat("en", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(new Date());

    this.setState({
      events: [event, ...this.state.events],
    });
  };
  clearEvents = () => {
    this.setState({
      events: [],
    });
  };
  createMarkup = () => {
    return { __html: this.state.query };
  };

  chatSubmit = (e) => {
    if (!this.state.query) {
      this.setState({ queryIssue: "Please enter your message" });
      return false;
    }
    var decodes = atob(this.state.tichekId);

    if (this.state.queryImage) {
      var fileSize = this.state.queryImage.size / 1024;
      if (fileSize > 1024) {
        toast.error("Maximum 1 MB Image allowed.");
        return false;
      }
    }

    const formData = new FormData();
    formData.append("userId", localStorage.id);
    formData.append("tichekId", decodes);
    formData.append("query", this.state.query);
    formData.append("queryImage", this.state.queryImage);
    formData.append("replyBy", 2);

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "multipart/form-data",
      },
    };

    this.setState({ btnDisable: true });
    setTimeout(() => {
      this.setState({ btnDisable: false });
    }, 500);

    axios
      .post(baseUrl + "/frontapi/chat-insert", formData, config)
      .then((res) => {
        var resp = res.data;
        if (resp.status === true) {
          this.ticketchat();
          this.setState({ query: " ", queryImage: "" });
          toast.success(resp.message);
        } else {
          toast.error(resp.message);
        }
      });
  };

  onChangeImage = (e) => {
    var queryImage = e.target.files[0];
    var fileSize = e.target.files[0].size / 1024;
    this.setState({ queryImage: "" });
    this.setState({ userUploadImage: false });
    this.setState({
      queryImage: queryImage,
    });
    this.setState({ userUploadImage: URL.createObjectURL(queryImage) });
    if (queryImage == false) {
      toast.error("Please select image.");
      return false;
    }
    if (fileSize > 1024) {
      toast.error("Maximum 1 MB Image allowed.");
      return false;
    }
    if (!queryImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      toast.error("Please select valid image.");
      return false;
    }
  };

  onChangeIssue = (event) => {
    // var query = editor.getData();
    var query = event.target.value;
    this.setState({
      query: query,
      queryIssue: "",
    });
    if (!query) {
      this.setState({
        queryIssue: "This field is required",
      });
    } else {
      this.setState({
        queryIssue: "",
      });
    }
  };

  showTableHtml = () => {
    var stylediv = "";
    var classThis = this;
    const html = [];
    let dateArray = [];
    this.state.tabledata.map((value, i) => {
      if (value.queryImage != "") {
        var profilePic = baseUrl + "/static/helpIssue/" + value.queryImage;
      }
      var date = "";

      if (value.replyBy == 1) {
        stylediv = "charting-part left_side_div";
        date = value.updatedAt;
      } else {
        stylediv = "charting-part right_side_div";
        date = value.createdAt;
      }
      let data = Moment(value.createdAt)
        .format("DD-MM-YYYY HH:mm:ss")
        .split(" ")[0];
      date = Moment(date).format("DD-MM-YYYY HH:mm:ss").split(" ");
      html.push(
        <div className={stylediv}>
          <div className="chatting-inner">
            <div style={{ textAlign: "center" }}>
              {Moment(this.state.dateTime)
                .format("DD-MM-YYYY HH:mm:ss")
                .split(" ")[0] == data ? (
                <></>
              ) : (
                !dateArray.includes(data) && data
              )}
            </div>

            {value.queryImage != "" &&
              value.queryImage != undefined &&
              value.queryImage != "NULL" && (
                <div>
                  <a
                    href=""
                    data-toggle="modal"
                    data-target="#supportImageModel"
                    onClick={() => {
                      classThis.setState({ Image: profilePic });
                    }}
                  >
                    <img src={profilePic} alt="" />
                  </a>
                  <div></div>
                </div>
              )}
            {value.replyBy == 1 ? (
              <div className="msg-outer user-msg">
                <div>{value.query}</div>
                <div className="msg-time">{date[1]}</div>
              </div>
            ) : (
              <div className="msg-outer">
                <div dangerouslySetInnerHTML={{ __html: value.query }} />
                <div className="msg-time">{date[1]}</div>
              </div>
            )}
          </div>{" "}
        </div>
      );
      !dateArray.includes(data) && dateArray.push(data);
    });
    return html;
  };

  TicketList = (event) => {
    var decodes = atob(this.state.tichekId);

    var userIdData = { ticketId: decodes };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/helplist-by-user-ticket", userIdData, config)
      .then((resp) => {
        var respNew1 = resp.data;
        this.setState({ resp1: resp.data });
        if (respNew1.status === true) {
          this.setState({
            tabledata1: respNew1.data,
            issueText: respNew1.data[0].issueType,
            issueImageSecond: respNew1.data[0].issueImage,
            dateTime: respNew1.data[0].createAt,
            issueDateTime: respNew1.data[0].createAt,
            respStatus1: respNew1.status,
            ResponceData: respNew1.data[0].status,
            SubSetx: respNew1.data[0].issue,
          });
        }
        // console.log("respNew1.data[0].status",respNew1.data[0].status)
        if (respNew1.data[0].status == 1) {
          this.setState({ OpenSteps: "Chat_panel", OpenStepsAll: false });
        }
      });
  };

  render() {
    const { t } = this.props;
    const meta = {
      title: `Support-Chat | ${ProductName}  Exchange`,
      description: `Login on ${ProductName} Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
      meta: {
        charset: "utf-8",
        name: {
          keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
        },
      },
    };
    let { OpenSteps, OpenStepsAll } = this.state;

    return (
    
      <DocumentMeta {...meta}>
        <div>
          <LoginHeader />
          <div className="new_inpage">
            <div className="container container2">
              <div className="profile_left_box bg_light">
                <div className="row">
                  <div className="col-md-8 m-auto">
                    <h4 className="mb-2">{t("Chatting")}</h4>
                    <div className="subject-status-outer d-flex flex-wrap justify-content-between row">
                      <h6 className="col-sm-6">
                        {t("Subject")}: {t(this.state.SubSetx)}
                      </h6>
                      <h6 className="status-outer col-sm-6 text-sm-right">
                        {t("Status")} :{" "}
                        {this.state.ResponceData == 0 ? (
                          <i
                            className="fa fa-clock-o"
                            aria-hidden="true"
                            style={{ color: "red" }}
                          >
                            {this.state.ResponceData == 0 && t("Pending")}
                          </i>
                        ) : (
                          <i
                            className="fa fa-check-circle-o"
                            style={{ color: "green" }}
                            aria-hidden="green"
                          >
                            Resolved
                          </i>
                        )}{" "}
                      </h6>
                    </div>
                    <div className="bg_white22">
                      {OpenSteps == "Chat_panel" || OpenStepsAll == "all" ? (
                        <>
                          {this.state.tabledata1.length != 0 && (
                            <div className=" chating-outer mb-4">
                              <div style={{ textAlign: "center" }}>
                                {console.log(
                                  "wefhjsgdftysdguysdfds",
                                  this.state.dateTime
                                )}
                                {
                                  Moment(this.state.dateTime)
                                    .format("DD-MM-YYYY HH:mm:ss")
                                    .split(" ")[0]
                                }
                              </div>
                              <div className="left_chat_scrool">
                                <div className="charting-part right_side_div">
                                  <div className="chatting-inner">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: this.state.issueText,
                                      }}
                                    />

                                    {this.state.issueImageSecond != "" &&
                                      this.state.issueImageSecond !=
                                        undefined &&
                                      this.state.issueImageSecond != "NULL" && (
                                        <div className="msg-outer image-msg user-img-msg">
                                          <a
                                            href="#e"
                                            data-toggle="modal"
                                            data-target="#supportImageModel"
                                            onClick={() => {
                                              this.setState({
                                                Image:
                                                  baseUrl +
                                                  "/static/helpIssue/" +
                                                  this.state.issueImageSecond,
                                              });
                                            }}
                                          >
                                            <img
                                              src={
                                                baseUrl +
                                                "/static/helpIssue/" +
                                                this.state.issueImageSecond
                                              }
                                              alt=""
                                            />
                                          </a>
                                          <div className="msg-time">
                                            {
                                              Moment(this.state.dateTime)
                                                .format("YYYY-MM-DD HH:mm:ss")
                                                .split(" ")[1]
                                            }
                                          </div>
                                        </div>
                                      )}
                                  </div>{" "}
                                </div>
                                {this.showTableHtml()}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {OpenSteps == "Send_Message" || OpenStepsAll == "all" ? (
                        <>
                          <div className="textarea_chatting position-relative">
                            <textarea
                              name="query"
                              value={this.state.query}
                              onChange={this.onChangeIssue}
                              placeholder={t("Enter Message")}
                              className="form-control form_control_textarea"
                            />

                            <div className="input_uploader">
                              <i className="fa fa-paperclip"></i>
                              <input
                                type="file"
                                className=""
                                name="queryImage"
                                onChange={this.onChangeImage}
                                accept=".jpg, .jpeg, .png"
                              />
                            </div>

                            <button
                              onClick={this.chatSubmit}
                              className="btn btn_man"
                              disabled={this.state.btnDisable}
                            >
                              <i className="fa fa-paper-plane"></i>
                            </button>
                          </div>
                          <label className="mb-0 d-inline">
                            {this.state.userUploadImage ? (
                              <img
                                src={this.state.userUploadImage}
                                className="img-fluid22 support-img"
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </label>
                          <p className="mt-0 " style={{ color: "red" }}>
                            {t(this.state.queryIssue)}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                  </div>
                  <div
                    className="modal fade supportImageModel"
                    id="supportImageModel"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-body p-0">
                          <img
                            className="w100"
                            src={this.state.Image}
                            alt="noimg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer limit={1} />
        </div>
      </DocumentMeta>
    );
  }
}

export default withTranslation()(chatting);
