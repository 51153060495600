import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios"
import { Modal } from "react-bootstrap";
import { baseUrl } from "../../Common/BaseUrl";
const PopUpModals = () => {
  const { t } = useTranslation();
  const [popUpModalData, setPopUpModalData] = useState([]);
  const [state, setState] = useState({popUpModal:false,sessionValue: localStorage.getItem("homepopshow")});
  //console.log("RE");
  useEffect(() => {
    getPopList()
  }, [])

  const getPopList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/popUplist", { type: "web", lang: localStorage.getItem("lang") }, config)
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          setPopUpModalData( res.data.data );
          //setPopModalData(res.data.data)
          let newDate = new Date();
          let timeStamp = parseInt(newDate.getTime() / 1000)
           console.log(this.state.sessionValue);
          if (res.data.data.length > 0 && (state.sessionValue == null || timeStamp >= state.sessionValue)) {
            setState({ popUpModal: true });
          }
        }
        //setExchange_tab(res.data);
      }).catch((e) => {
        console.log(e);
      });
  }

  const closePopUpModel = () => {
  //  console.log("clicked");
    let newDate = new Date();
    let timeStamp = parseInt(newDate.getTime() / 1000)
    timeStamp += 86400;
    localStorage.setItem("homepopshow", timeStamp);
    setState({ popUpModal: false });
    //setPopModalOpen(false);
  }
  const closeImage = (val, index) => {
    let tempPopup = [...popUpModalData];
   // console.log("clicked", val);
     val.view = false;
  //  console.log(popUpModalData[index]);
    let i = 0;
    //tempPopup[index] = val;
    tempPopup.map((ele, ind) => {
     // console.log(ele);
     // console.log(ele);
      if (!ele.view) {
        i += 1;
      }
    })
    setPopUpModalData(tempPopup)
    //popUpModalData = tempPopup;
    ///console.log("close ", i, " ", popUpModalData.length);
    if (i == popUpModalData.length) {
       closePopUpModel()
    }
  }

  return (
    <>
      <Modal
        size="lg"
        fullscreen={true}
        show={state.popUpModal}
        onHide={closePopUpModel}
        keyboard={false}
      >
        {/* <Modal.Header closeButton>

                        </Modal.Header> */}
        <Modal.Body>

          {popUpModalData.map((val, index) =>
            (val.view) ? <div className="image_popup" key={index}>
              <p><span style={{ fontSize: "25px" }}>{val.header} </span><i className="fa fa-close" style={{ float: "right", margin: "15px", cursor: "pointer" }} onClick={(e) => closeImage(val, index)}></i></p>

              <img
                className=""
                src={baseUrl + "/static/popups/" + val.logo}
                alt="coin"
                style={{ width: "100%" }}
              />
            </div> : "")}
        </Modal.Body>
      </Modal>
    </>

  )
}

export default PopUpModals