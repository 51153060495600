import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import LoginHeader from "../Common/LoginHeader";
import Footer from "../Common/Footer";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../Common/BaseUrl";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import Switch from "react-switch";
import Moment from "moment";
import LeftTab from "../Common/LeftTab";
import ChangePassword from "./ChangePassword";
import authservice from "../../services/auth.service";
const meta = {
  title: "Profile | DBI  Exchange",
  description:
    "DBI  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://DBI  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

const Profile = (props) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile_number, setMobile_number] = useState("");
  const [kycStatus, setKycStatus] = useState("N");
  const [type, setType] = useState("password");
  const [OldPassword, setOldPassword] = useState("");
  const [OldPassError, setOldPassError] = useState("");
  const [type1, setType1] = useState("password");
  const [NewPassword, setNewPassword] = useState("");
  const [NewPasswordError, setNewPasswordError] = useState("");
  const [type2, setType2] = useState("password");
  const [CNewPasswordError, setCNewPasswordError] = useState("");
  const [btnDisableOP, setBtnDisableOP] = useState(false);
  const [btnDisableNP, setBtnDisableNP] = useState(false);
  const [btnDisableCNP, setBtnDisableCNP] = useState(false);
  const [image, setImage] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [respStatus, setRespStatus] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [qrcodeurl, setQrcodeurl] = useState("assets/img/qr-dummy.png");
  const [SKey, setSKey] = useState("");
  const [checkTwoFa, setCheckTwoFa] = useState("");
  const [vcode, setVcode] = useState("");
  const [TwoFaText, setTwoFaText] = useState("");
  const [message, setMessage] = useState("");
  const [isBlur, setIsBlur] = useState(true);
  const [profileImg, setprofileImg] = useState("");
  const [loginUser, setLoginUser] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [show, setShow] = useState(false);
  const [errors, setError] = useState({ old_pin: "", new_pin: "", confirm_pin: "" });
  const [touched, setTouched] = useState({});
  const [pinState, setPinState] = useState({ old_pin: "", new_pin: "", confirm_pin: "" });


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUserDetails();
    LoginActivity();
    referralList();
    generateGoogleCode();
  }, []);
  // const saveFile = async (e) => {
  //   e.preventDefault();
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   };
  //   const formData = new FormData();
  //   formData.append("image", e.target.files[0]);

  //   try {
  //     const res = await axios.put(
  //       baseUrl + "/frontapi/update-profile-image",
  //       formData,
  //       config
  //     );

  //   } catch (ex) {
  //     console.log(ex);
  //   }
  // };
  const getUserDetails = () => {
    let r = (Math.random() + 1).toString(36).substring(7);
    //console.log("random", r);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((res) => {
      var resp = res.data;
      if (resp.status === true) {
        setLoginUser(resp.data[0])
        setFirstName(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        setEmail(resp.data[0].email);
        setMobile_number(resp.data[0].mobile_no);
        setKycStatus(resp.data[0].kycVerification_status);
        setImage(resp.data[0].profile_image);
        setBtnDisableOP(true)
        if (
          resp.data[0].enable_2fa == "N" ||
          resp.data[0].enable_2fa == "null"
        ) {
          setTwoFaText("Enable");
        }
        if (resp.data[0].enable_2fa == "Y") {
          setTwoFaText("Disable");
        }
        setCheckTwoFa(resp.data[0].enable_2fa);
      } else {
        if (
          resp.key === "logout" &&
          localStorage.getItem("token") != "null" &&
          localStorage.getItem("token") !== null
        ) {
          toast.error(resp.message);
          localStorage.clear();
          setTimeout(() => this.props.history.push("/login"), 2000);
        }
      }
    });
  };

  const generateGoogleCode = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/generateGoogleCode", {}, config)
      .then((resp1) => {
        var resp = resp1.data;
        setQrcodeurl(resp.data.qrCodeUrl);
        setSKey(resp.data.secretKey);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var regex =
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

    if (!OldPassword && !NewPassword && !CPassword) {
      setOldPassError("This field is required");
      setNewPasswordError("This field is required");
      setCNewPasswordError("This field is required");
      return false;
    }

    if (OldPassword == "" || OldPassword == undefined) {
      setOldPassError("This field is required");
      return false;
    }
    if (NewPassword == "" || NewPassword == undefined) {
      setNewPasswordError("This field is required");
      return false;
    }

    if (!NewPassword.match(regex)) {
      setNewPasswordError(
        "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol"
      );
      return false;
    } else {
      setNewPasswordError("");
    }
    if (CPassword == "" || CPassword == undefined) {
      setCNewPasswordError("This field is required");
      return false;
    }

    if (NewPassword !== CPassword) {
      toast.error("Password mis-matched");
      return false;
    }

    // Updating Password //

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let LoginData = {
      NewPassword: NewPassword,
      OldPassword: OldPassword,
      CPassword: CPassword,
    };

    axios
      .post(baseUrl + "/frontapi/changePassword", LoginData, config)
      .then((resp) => {
        var response = resp.data;
        setNewPassword("");
        setOldPassword("");
        setCPassword("");
        if (response.status === true) {
          toast.dismiss();
          toast.success(t(response.message));
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login-to-buy-sell-crypto";
          }, 1000);
          return false;
        } else {
          toast.dismiss();
          toast.error(response.message);
          return false;
        }
      });
  };

  const handleClick = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const handleClick1 = () => {
    if (type1 === "text") {
      setType1("password");
    } else {
      setType1("text");
    }
  };

  const handleClick2 = () => {
    if (type2 === "text") {
      setType2("password");
    } else {
      setType2("text");
    }
  };

  const handleChangePin = (e) => {
     console.log(e.target.value);
    console.log(e.target.name);
    let message = "";
    let oldmessage = "";
    let confirmMessage = "";
    if (e.target.value.length < 7) {
      setPinState({ ...pinState, [e.target.name]: e.target.value })
      if (e.target.name == "old_pin") {
        if (e.target.value.length < 6) {
          oldmessage = "Old pin must be 6 digit";
        }
      }
      if (e.target.name == "new_pin") {
        if (e.target.value.length < 6) {
          message = "New pin must be 6 digit";
        }
      }
      if (e.target.name == "confirm_pin") {
        if (e.target.value != pinState.new_pin) {
          confirmMessage = "New pin and Confirm not matched";
        }
      }
    }
    setError({ ...errors, old_pin: oldmessage, new_pin: message, confirm_pin: confirmMessage })
  }

  const handleClickNew = () => {

  }

  const createPin = () => {
    let valid = true;
    let errors = {};
    let userData = JSON.parse(localStorage.getItem("userData"));
    console.log(userData.login_pin);

    if (pinState.old_pin == "") {
      valid = false;
      errors.old_pin = "This field is required"
    }else if(pinState.old_pin.length < 6) {
      valid = false;
      errors.old_pin = "Old pin must be 6 digit"
    }
    if (pinState.new_pin == "") {
      valid = false;
      errors.new_pin = "This field is required"
    }else if(pinState.new_pin.length < 6) {
      valid = false;
      errors.new_pin = "New pin must be 6 digit"
    }
    if (pinState.confirm_pin == "") {
      valid = false;
      errors.confirm_pin = "This field is required"
    }else if(pinState.confirm_pin.length < 6) {
      valid = false;
      errors.confirm_pin = "Confirm pin must be 6 digit"
    }

    if (pinState.new_pin != "" && pinState.confirm_pin != "" && pinState.new_pin != pinState.confirm_pin) {
      valid = false;
      errors.confirm_pin = "New Pin and Confirm Pin not matched"
    }

    if (pinState.new_pin != "" && pinState.old_pin != "" && pinState.old_pin != userData.login_pin) {
      valid = false;
      errors.old_pin = "Old Pin is not valid"
    }
    if (pinState.new_pin != "" && pinState.old_pin != "" && pinState.new_pin == pinState.old_pin) {
      valid = false;
      errors.new_pin = "Old Pin and New Pin is same, Please Change"
    }

    if (valid && Object.keys(errors) == 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      axios.post(baseUrl + "/frontapi/userLoginPinUpdate", { new_pin: pinState.new_pin, old_pin: pinState.old_pin }, config).then((res) => {
        console.log(res);
        var resp = res.data;
        if (res.data.status == "success") {
          authservice.getUserDetail()
          toast.dismiss();
          toast.success(t(res.data.message));
          setPinState({ old_pin: "", new_pin: "", confirm_pin: "" })
        }else{
          toast.dismiss();
          toast.error(t(res.data.message));
        }
      }).catch((e) => {
        console.log(e);
      })
    } else {
      setError(errors)
      return false
    }

  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name == "OldPassword") {
      var OldPassword = event.target.value;
      var OldPassError = "";
      if (!OldPassword) {
        toast.dismiss();
        OldPassError = "This field is required";
        setOldPassError(OldPassError);
        //setBtnDisableOP(false);
        setOldPassword(OldPassword);
        return false;
      } else {
        setOldPassError("");
        // setBtnDisableOP(true);
        setOldPassword(OldPassword);
      }
    }

    if (name == "NewPassword") {
      var NewPasswordd = event.target.value;
      var NewPasswordError = "";
      if (!NewPassword) {
        toast.dismiss();
        NewPasswordError = "This field is required";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);
        setNewPassword(NewPasswordd);
        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
      if (NewPassword.length < 7) {
        toast.dismiss();
        NewPasswordError =
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);

        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
      var regex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-8])(?=.*[!@#$%^&*]).{7,}$";
      if (!NewPassword.match(regex)) {
        NewPasswordError =
          "Password must be at least 8 characters long, contains an upper case letter, a lower case letter, a number, and a symbol";
        setNewPasswordError(NewPasswordError);
        setBtnDisableNP(false);
        return false;
      } else {
        setNewPasswordError("");
        setBtnDisableNP(true);
        setNewPassword(NewPasswordd);
      }
    }

    if (name == "CPassword") {
      var CPassword = event.target.value;
      var CNewPasswordError = "";
      if (!CPassword) {
        toast.dismiss();
        CNewPasswordError = "This field is required";
        setCNewPasswordError(CNewPasswordError);
        setBtnDisableCNP(false);
        setCPassword(CPassword);
        return false;
      } else {
        setCNewPasswordError("");
        setBtnDisableCNP(true);
        setCPassword(CPassword);
      }
      console.log("NewPassword != CPassword", NewPassword != CPassword);
      if (CPassword != NewPassword) {
        toast.dismiss();
        CNewPasswordError = "Confirm password does not matched";
        setCNewPasswordError(CNewPasswordError);
        setBtnDisableCNP(false);
        setCPassword(CPassword);
        // return false;
      } else {
        setCNewPasswordError("");
        setBtnDisableCNP(true);
        setCPassword(CPassword);
      }
    }
    if (name === "vcode") {
      var vcode = event.target.value;
      setVcode(vcode);
      if (vcode.replace(/\s+/g, "").length == 0) {
        setMessage("Please enter a valid code");
        return false;
      }
      setMessage("");
    }
  };

  const verifyCode = () => {
    if (!vcode) {
      setMessage("This field is required");
      return false;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let faData = {
      code: vcode,
      TwoFaText: TwoFaText,
    };
    axios
      .post(baseUrl + "/frontapi/verifyGoogleCode", faData, config)
      .then((resp) => {
        setIsBlur(false);
        // setMessage(resp.data.message);
        // "code verified"
        if (resp.data.status) {
          toast.success(t(resp.data.message));
        } else {
          toast.error(t(resp.data.message));
        }
        getUserDetails();
      });
  };

  const LoginActivity = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        setTabledata(respNew.findData);
        setRespStatus(respNew.status);
      }
    });
  };

  const referralList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/user_referal_list", {}, config).then((resp) => {
      var respNew = resp.data;
      if (respNew.status === true) {
        setTransactions(respNew.data);
      }
    });
  };

  const showTableHtml = () => {
    if (respStatus === true) {
      const html = [];
      var snno = 0;
      tabledata.map(function (value, i) {
        console.log("value.createdAt", value.createdAt);
        snno++;
        html.push(
          <tr>
            <td> {snno} </td>
            <td>{Moment(value.createdAt).format("lll")}</td>
            <td>{value.event}</td>
            <td>{value.device}</td>
            <td>{value.browser}</td>
            <td>{value.osName}</td>
            <td>{value.ip}</td>
            <td>
              {value.cityName}, {value.countryName}
            </td>
          </tr>
        );
      });
      return html;
    }
  };

  const updateProfileImage = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    axios
      .post(baseUrl + "/frontapi/update-profile-image", formData, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          getUserDetails();
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };

  const deleteAccount = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/delete-account", {}, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });
  };

  const claimReferral = (e) => {
    console.log(e);
    if (e) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .post(baseUrl + "/frontapi/claim_referal", e, config)
        .then((resp) => {
          var response = resp.data;
          if (response.status === true) {
            toast.dismiss();
            toast.success(response.message);
            referralList();
            getUserDetails();
          } else {
            toast.dismiss();
            toast.error(response.message);
          }
        });
    }
  };

  const generateReferal = () => {


    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .post(baseUrl + "/frontapi/generate_referal", {}, config)
      .then((resp) => {
        var response = resp.data;
        if (response.status === true) {
          toast.dismiss();
          toast.success(response.message);
          getUserDetails();
        } else {
          toast.dismiss();
          toast.error(response.message);
        }
      });

  };



  const keyCopy = () => {
    //console.log("click ", SKey);
    navigator.clipboard.writeText(SKey)
    toast.success(t("Copied to clipboard"));
  }

  const showTableHtml1 = (value) => {

    // console.log(value);

    let senddata = [];

    if (value.length == 0) {

    } else {
      value.forEach((e, i) => {
        senddata.push(
          <tr>
            <td className="">
              {i + 1}
            </td>
            <td className="">
              {e.other_user_ref}
            </td>
            <td className="">
              {e.referred_amount}
            </td>
            <td className="">
              {Moment(e.created).format("lll")}
            </td>
            <td className="">
              {timeDiff(e.enable_date_time)}
            </td>
            {/* <td className="">
             {e.enabled}
            </td> */}
            <td className="">
              {(e.enabled == 1) ?
                <button className="btn btn_man" onClick={() => claimReferral(e)} type="button">{t("Claim Now")}</button> : (e.enabled == 0) ? t("Upcoming") : t("Claimed")}
            </td>
          </tr>)
      })
    }

    return <tbody className="">{senddata}</tbody>
  }

  const timeDiff = (time) => {
    let newDate = new Date();
    let timeStr = parseInt(newDate.getTime() / 1000);
    let days = 0;
    if (time > timeStr) {
      const total = time - timeStr;
      days = Math.floor(total / (60 * 60 * 24));
    }
    return days
  }



  const maxCharacters = 19;
  const truncatedEmail =
    email && email.length > maxCharacters
      ? email.slice(0, maxCharacters) + "..."
      : email;
  return (
    <div>
      <DocumentMeta {...meta}>
        <LoginHeader />
        <div className="bghwhite">
          <div className="new_inpage">
            <div className="container container2">
              <LeftTab />
              <div className="google-auth ">
                <div className="row">
                  <div className="auth-left col-lg-3 mb-3">
                    <div className="white_box h_100 mb-0">
                      <div className="user_profile">
                        {image == "" || image == undefined || image == null ? (
                          <img
                            src="/assets/img/user_profile.png"
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={baseUrl + "/static/profile/" + image}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                        <div className="edit-profile position-absolute">
                          <input
                            type="file"
                            // ref={myref}
                            id="img1"
                            style={{ display: "none" }}
                            onChange={updateProfileImage}
                          />
                          <label htmlFor="img1" className="editlabel">
                            <i className="fa fa-edit"></i>
                          </label>
                        </div>
                      </div>
                      <h5 className="user text-center mt-3 mb-5">
                        {firstName} {lastName == "null" ? "" : lastName}
                      </h5>

                      <div className="profile-info info-main-outer ">
                        <ul>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("Email")}:</span>
                            <span className="col-auto ml-auto" title={email}>
                              {truncatedEmail}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("Mobile No")}:</span>
                            <span className="col-auto ml-auto">
                              {mobile_number}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("KYC")}:</span>
                            <span className="col-auto ml-auto">
                              {kycStatus === "Y" ? t("Completed") : t("Pending")}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("Delete Account")}:</span>
                            <span className="col-auto ml-auto">
                              <button
                                className="btn btn-danger"
                                onClick={handleShow}
                              >
                                {t("Delete")}
                              </button>
                            </span>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                  <ChangePassword />
                  <div className="auth-left col-lg-3 mb-3">
                    <div className="white_box h_100 mb-0">
                      <h5>{t("Change Pin")}</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group eye_pass eye_pass_two">
                            <label>{t("Old Pin")}</label>
                            <input
                              type="number"
                              pattern='[0-9]{0,5}'
                              className="form-control"
                              name="old_pin"
                              value={pinState.old_pin}
                              onKeyDown={(event) => {                                
                                if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
                                    event.preventDefault();
                                }
                            }}
                              onChange={(e)=>handleChangePin(e)}
                              placeholder={t("enter") + " " + t("Old Pin")}
                            />
                            <span
                              className="password__show eye1"
                              onClick={handleClickNew}>
                              {type2 === "text" ? (
                                <i className="las la-eye"></i>
                              ) : (
                                <i className="las la-low-vision"></i>
                              )}
                            </span>

                            {errors.old_pin && (
                              <div style={{ color: "red" }}>
                                {t(errors.old_pin)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group eye_pass eye_pass_two">
                            <label>{t("New Pin")}</label>
                            <input
                              type="number"
                              className="form-control"
                              name="new_pin"
                              value={pinState.new_pin}
                              onKeyDown={(event) => {                                
                                if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
                                    event.preventDefault();
                                }
                            }}
                              onChange={(e)=>handleChangePin(e)}
                              placeholder={t("enter") + " " + t("New Pin")}
                            />
                            <span
                              className="password__show eye1"
                              onClick={handleClickNew}>
                              {type2 === "text" ? (
                                <i className="las la-eye"></i>
                              ) : (
                                <i className="las la-low-vision"></i>
                              )}
                            </span>

                            {errors.new_pin && (
                              <div style={{ color: "red" }}>
                                {t(errors.new_pin)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group eye_pass eye_pass_two">
                            <label>{t("Confirm Pin")}</label>
                            <input
                              type="number"
                              className="form-control"
                              name="confirm_pin"
                              value={pinState.confirm_pin}
                              onKeyDown={(event) => {                                
                                if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
                                    event.preventDefault();
                                }
                            }}
                              onChange={(e)=>handleChangePin(e)}
                              placeholder={t("Confirm Pin")}
                            />
                            <span
                              className="password__show eye1"
                              onClick={handleClickNew}>
                              {type2 === "text" ? (
                                <i className="las la-eye"></i>
                              ) : (
                                <i className="las la-low-vision"></i>
                              )}
                            </span>

                            {errors.confirm_pin && (
                              <div style={{ color: "red" }}>
                                {t(errors.confirm_pin)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 ">
                          <button className="btn btn_man fs14" onClick={createPin}
                          // disabled={
                          //   !btnDisableOP ||
                          //   !btnDisableNP ||
                          //   !btnDisableCNP
                          // }
                          >
                            {t("Change Pin")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="auth-left col-lg-3 mb-3">
                    <div className="white_box h_100 mb-0">
                      <h5>{t("Google Authentication")}</h5>
                      <div className="google-auth">
                        {(loginUser.google_auth_verify != "Y") ?
                          <>
                            <div className="auth-left mb-3">
                              <div
                                className={
                                  isBlur == true
                                    ? "qr-outer kyc_bg qr_load"
                                    : "qr-outer kyc_bg"
                                }
                              >
                                <img
                                  className="img-fluid"
                                  src={qrcodeurl}
                                  alt="qrCode"
                                />
                              </div>
                            </div>

                            <div className="auth-right">
                              <h5 className="kyc_h5">
                                {t("Key")} :<span className=""> {SKey}{" "}
                                  <i className="fa fa-copy ml-2 clip-copy" onClick={keyCopy}></i> </span>
                              </h5>

                              <h5>
                                {t("Status")}: {checkTwoFa == "Y" ? t("Enabled") : t("Disabled")}
                              </h5>
                              <label className="fw-500">{t("Authentication Code")}</label>
                              <div className="row">
                                <div className="col-md-12 pr-0">
                                  <div className="form-group eye_pass eye_pass_two">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="vcode"
                                      placeholder={t("enter") + " " + t("code")}
                                      value={vcode}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <button
                                    onClick={verifyCode}
                                    className="btn btn_man w100px"
                                  >
                                    {t(TwoFaText)}
                                  </button>
                                </div>
                              </div>
                              {/* {console.log(
                            "sdfhgsdfuygsdfsd",
                            message === "code verified"
                          )} */}
                              <p
                                className="mt-0"
                                // style={
                                //   message == " code verified  "
                                //     ? { color: "green" }
                                //     : { color: "red" }
                                // }
                                style={{ color: "red" }}
                              >
                                {t(message)}
                              </p>
                            </div>
                          </>
                          :
                          <h5>{t("Completed")}</h5>
                        }
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="white_box h_100 mb-0">
                      <h5>{t("Referral Amount")}</h5>



                      <div className="profile-info info-main-outer ">
                        <ul>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("Your Referral Id")}:</span>
                            {(!loginUser.refer_id && btnDisableOP) ?
                              <button
                                className="btn btn_man fs14"
                                onClick={() => generateReferal()} >
                                {t("Generate Your Referral")}
                              </button>
                              :
                              <span className="col-auto ml-auto">
                                {loginUser?.refer_id}
                              </span>

                            }
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("Total Amount")}:</span>
                            <span className="col-auto ml-auto">
                              {loginUser?.total_referal_amount}</span>

                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("Available Amount")}:</span>
                            <span className="col-auto ml-auto">
                              {loginUser?.available_referal_amount}
                            </span>
                          </li>
                          {/* <li className="user row mb-2">
                            <span className="col-auto">{t("Mobile No")}:</span>
                            <span className="col-auto ml-auto">
                              {mobile_number}
                            </span>
                          </li>
                          <li className="user row mb-2">
                            <span className="col-auto">{t("KYC")}:</span>
                            <span className="col-auto ml-auto">
                              {kycStatus === "Y" ? t("Completed") : t("Pending")}
                            </span>
                          </li>*/}


                        </ul>
                        <div className="">
                          <div className="row">
                            {/* <div className=" col-8 pr-0">
                        <input
                                type="text"
                                className="form-control"
                                name="vcode"
                                placeholder={t("enter")+" "+t("amount")}
                                value={vcode}
                                onChange={handleChange}
                              />
                          </div> */}
                            {/* <div className="col-8">

                              <button
                                className="btn btn_man fs14"
                                onClick={() => claimReferral(loginUser?.available_referal_amount)}
                                disabled={loginUser?.available_referal_amount == 0}>
                                {t("Claim Amount")}
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* </div>
                  <div className="col-lg-8 mb-3"> */}
                      {/* <div className="white_box h_100 mb-0"> */}

                      <div className="rows">
                        <div className="wallet_box">
                          <div className="table-responsive">
                            <div className="scrool_wallet_box">
                              <table className="table table-striped mb-0 ">
                                <thead>
                                  <tr>
                                    <th className="">
                                      {t("Sno.")}
                                    </th>
                                    <th className="">
                                      {t("Refered User")}
                                    </th>
                                    <th>{t("Amount")}</th>
                                    <th>{t("Created At")}</th>
                                    <th>{t("Days Left")}</th>
                                    {/* <th>{t("Status")}</th> */}
                                    <th>{t("Action")}</th>
                                  </tr>
                                </thead>
                                {showTableHtml1(transactions || [])}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              {/* <h5>Your Recent Activity</h5>
            <div className="white_box fs12 ">
              <div className="scrool_wallet_box">
                <table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th> Sr No</th>
                      <th>Date (IST) </th>
                      <th>Event</th>
                      <th>Device</th>
                      <th>Browser</th>
                      <th>Operation System</th>
                      <th>IP</th>
                      <th>Address</th>
                    </tr>
                  </thead>

                  <tbody>{showTableHtml()}</tbody>
                </table>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Delete Account")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("Woohoo") + ",  " + t("Are you sure you want to delete account ?")}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
            <Button variant="danger" onClick={deleteAccount}>
              {t("Delete")}
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
        <Footer />
      </DocumentMeta>
    </div>
  );
};

export default Profile;
