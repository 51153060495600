import React from "react";
import { Component } from "react";
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import DocumentMeta from "react-document-meta";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import LeftTab from "../Common/LeftTab";
import { withTranslation } from 'react-i18next';
import {
  cryptoCoinList,
  deleteWhiteList,
  userData,
  whiteListAdd,
  whiteListData,
} from "../services/WhiteListServices";

const baseUrl = myConstList.baseUrl;
const meta = {
  title: "Whitelist | DBI  Exchange ",
  description:
    "DBI  Exchange Live Makes The Buying And Selling Of Crypto Coin Way More Effective And Profitable. We Are A Low Cost Cyrpto Exchange In India Like Bitcoin And Dogecoin.",
  canonical: "https://DBI  Exchange.live/buy-sell-crypto-online",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "BTC Exchange, Crypto Trading Platform, Best Crypto Converter, Indian Crypto Exchange",
    },
  },
};

export class WhiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      BtnDisable: false,
      cryptoCheckedBoxes: [],
      modalIsOpen: false,
      Cryptodata: [],
    };
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.GetUserData();
  }

  componentWillMount = () => {
    this.ShowListData();
    this.CryptoCoinData();
    this.GetUserData();
  };

  AddWhitelist = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "", coinTypeError: "" });
    if (name === "coinType") {
      if (value === "") {
        this.setState({
          coinTypeError: "This field is required",
        });
        return false;
      }

      this.setState({
        coinTypeError: "",
      });
    }
    if (name === "whitelistAddress") {
      if (value === "") {
        this.setState({
          walletError: "This field is required",
        });
        return false;
      }
      if (!(value.length >= 26 && value.length <= 42)) {
        this.setState({
          walletError: "Please enter a valid wallet address",
        });
        return false;
      }

      this.setState({
        walletError: "",
      });
    }
    if (name === "name") {
      if (value === "") {
        this.setState({
          nameError: "This field is required",
        });
        return false;
      }

      this.setState({
        nameError: "",
      });
    }
  };

  onBlurCoinType = (event) => {
    var coinList = event.target.value;
    if (!coinList) {
      toast.dismiss();
      return false;
      // toast.error("Select Coin Type");
    }
    if (this.state.TableData) {
      for (let i = 0; i <= this.state.TableData.length - 1; i++) {
        if (this.state.TableData[i].coinType == this.state.coinType) {
          console.log(
            "insiderif",
            this.state.TableData[i].coinType,
            this.state.coinType
          );
          if (
            this.state.TableData[i].whitelistAddress ==
            this.state.whitelistAddress
          ) {
            this.setState({ walletError: "Wallet address already exist" });
            //toast.error("Wallet address already exist")
            return false;
          } else {
            this.setState({ walletError: "" });
          }
        } else {
          this.setState({ walletError: "" });
        }
      }
    }
  };

  onBlurName = (event) => {
    var name = event.target.value;
    if (!name) {
      this.setState({ nameError: "Name filed is required" });
      return false;
    }
    if (name.replace(/\s+/g, "").length == 0) {
      this.setState({
        nameError: "Please enter a valid name",
      });
      return false;
    }
    if (name.length > 30) {
      this.setState({
        nameError: "Keyword must not exceed 30 characters",
      });
      return false;
    }
    this.setState({
      nameError: "",
    });
  };
  onBlurWhitelistAddress = (event) => {
    var address = event.target.value;
    if (!address) {
      this.setState({
        walletError: "Wallet address is required",
      });
      return false;
      // toast.error("Enter Wallet Address");
    }
    if (address.includes(" ")) {
      this.setState({
        walletError: "Please enter a valid wallet address",
      });
      return false;
    }
    // if (address.replace(/\s+/g, '').length == 0) {
    //   this.setState({
    //     walletError:"Please enter a valid wallet address"
    //   })
    //   return false
    // }
    // if (address.length <= 19 || address.length >= 36) {
    //   this.setState({
    //     walletError: "Please enter a valid wallet address"
    //   })
    //   return false
    // }
    this.setState({
      walletError: "",
    });
  };

  CryptoCoinData = async () => {
    let result = await cryptoCoinList();
    // axios.get(baseUrl+"/frontapi/cryptocoin_list").then((res)=>{
    var resp = result.data;
    this.setState({ CryptoStatus: result.status, Cryptodata: resp });
    // })
    // if(result.status){

    // }
  };

  CryptoCoinDataHtml = () => {
    const { t } = this.props;
    // console.log("cryptocoinlist",this.state.Cryptodata)
    if (this.state.CryptoStatus === true) {
      const html = [];
      const classThis = this;
      html.push(<option value>{t("Select")}</option>);
      this.state.Cryptodata.map(function (value, i) {
        html.push(<option value={value.id}>{value.short_name}</option>);
      });

      return (
        <select
          className="form-control"
          name="coinType"
          value={classThis.state.coinType}
          onChange={classThis.handleChange}
          onBlur={this.onBlurCoinType}
        >
          {html}
        </select>
      );
    }
  };

  //  Disable = (address_Id,addressStatus) => {
  //     var address_Id = address_Id;
  //     var addressStatus=addressStatus;
  //         if(addressStatus==="0")
  //      {
  //          addressStatus="1";

  //      }
  //      else if(addressStatus==="1")
  //      {

  //          addressStatus="0";

  //      }
  //     let headerData = {
  //         headers : {'x-access-token':localStorage.getItem('token')}
  //       };

  //       var offer={
  //      address_Id:address_Id,
  //       status:addressStatus,

  //       }

  //        axios.post(baseUrl + '/frontapi/users/addressStatusUpdate',offer, headerData).then((resp) => {
  //         var resp = resp.data;
  //        //  console.log("resf",resp.data);
  //               if(resp.status===true) {
  //                   {this.getBuyOfferListdata()};
  //               }
  //         });
  //     }

  ChangeStatus = (address_Id, addressStatus) => {
    var address_Id = address_Id;
    var addressStatus = addressStatus;
    if (addressStatus === "0") {
      addressStatus = "1";
    } else if (addressStatus === "1") {
      addressStatus = "0";
    }

    let headerData = {
      headers: { "x-access-token": localStorage.getItem("token") },
    };

    var offer = {
      address_Id: address_Id,
      status: addressStatus,
    };

    axios
      .post(baseUrl + "/frontapi/users/addressStatusUpdate", offer, headerData)
      .then((resp) => {
        var resp = resp.data;

        if (resp.status === true) {
          {
            this.getBuyOfferListdata();
          }
        }
      });
  };

  GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    };
    let result = userData();
    var resp = result.data;
    if (!localStorage.getItem("token")) {
      this.setState({
        kycVerification_status: "Y",
      });
      return false;
    }
    if (localStorage.getItem("token")) {
      this.setState({
        kycStatus: resp.data[0].kycVerification_status,
      });
    }

    if (resp.data[0].kycVerification_status === "Y") {
      this.setState({ modalIsOpen: false });
    }
    if (resp.data[0].kycVerification_status === "N") {
      this.setState({ modalIsOpen: true });
    }
  };

  handleSubmit = async () => {
    console.log(
      "handlesubmit",
      this.state.coinType,
      this.state.name,
      this.state.whitelistAddress
    );
    if (
      this.state.coinTypeError ||
      this.state.nameError ||
      this.state.walletError
    ) {
      return false;
    }
    if (this.state.coinType == undefined || this.state.coinType == "true") {
      this.setState({
        coinTypeError: "This field is required",
      });
      return false;
    }
    if (!this.state.name) {
      this.setState({
        nameError: "This field is required",
      });
      return false;
    }
    if (!this.state.whitelistAddress) {
      this.setState({
        walletError: "This field is required",
      });
      return false;
    }

    if (this.state.TableData) {
      for (let i = 0; i <= this.state.TableData.length - 1; i++) {
        if (this.state.TableData[i].coinType == this.state.coinType) {
          console.log(
            "insiderif",
            this.state.TableData[i].coinType,
            this.state.coinType
          );
          if (
            this.state.TableData[i].whitelistAddress ==
            this.state.whitelistAddress
          ) {
            this.setState({ walletError: "Wallet address already exist" });
            //toast.error("Wallet address already exist")
            return false;
          } else {
            // this.setState({ walletError: "" })
          }
        } else {
          // this.setState({ walletError: "" })
        }
      }
    }
    this.setState({ BtnDisable: true });
    console.log(
      "this.state.whitelistAddress.length",
      this.state.whitelistAddress.length
    );
    if (
      this.state.whitelistAddress.length < 26 ||
      this.state.whitelistAddress.length > 42
    ) {
      this.setState({ walletError: "Please enter a valid wallet address" });
      this.setState({ BtnDisable: false });
      return false;
    }
    if (this.state.name.length > 30 || this.state.name.length <= 1) {
      this.setState({ nameError: "Please enter a valid name" });
      this.setState({ BtnDisable: false });
      return false;
    }
    var walletAddress = {
      whitelistAddress: this.state.whitelistAddress,
      coinType: this.state.coinType,
      name: this.state.name,
    };
    const token = localStorage.getItem("token");
    let result = await whiteListAdd(walletAddress, token);
    console.log("dresultdqatatata", result);
    var resp = result.data;
    this.setState({
      msg: resp.message,
      BtnDisable: false,
      Name: "",
      whitelistAddress: "",
      coinType: "",
    });
    if (resp.status === true) {
      this.setState({
        isModalOpen: false,
        whitelistAddress: "",
        name: "",
      });
      toast.success(resp.message);
    } else {
      toast.error(resp.message);
    }
    this.ShowListData();

    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };

    // axios
    //   .post(baseUrl + "/frontapi/whitelist", walletAddress, config)
    //   .then((resp) => {
    //     var resp = resp.data;
    //     this.setState({
    //       msg: resp.message,
    //       BtnDisable: false,
    //       Name: "",
    //       whitelistAddress: "",
    //       coinType: "",
    //     });

    //     if (resp.status === true) {
    //       this.setState({
    //         isModalOpen: false,
    //         whitelistAddress: "",
    //         name: " ",
    //       });
    //       // toast.success(resp.message);
    //     } else {
    //       toast.error(resp.message);
    //     }
    //     this.ShowListData();
    //   });
  };

  ShowListData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    var walletAddress = {
      userId: localStorage.getItem("id"),
    };
    let result = await whiteListData(walletAddress);
    var resp1 = result.data;
    this.setState({ msg: resp1.message });
    if (resp1.status === true) {
      this.setState({ TableData: resp1.data, TableStatus: resp1.status });
    } else {
      this.setState({ TableData: [] });
    }
    console.log("tokentokentoken", result);

    return false;
    axios
      .post(baseUrl + "/frontapi/whitelistData", walletAddress, config)
      .then((resp) => {
        var resp1 = resp.data;
        this.setState({ msg: resp.message });

        if (resp1.status === true) {
          this.setState({ TableData: resp1.data, TableStatus: resp1.status });
        } else {
          this.setState({ TableData: [] });
        }
      });
  };

  whitelistDataHtml = () => {
    if (this.state.TableStatus === true) {
      const html = [];
      const classThis = this;
      this.state.TableData.map(function (value, i) {
        html.push(
          <div className="whitelist-data">
            <div className="white-top d-flex justify-content-between">
              <div className="white-right-inner">
                <h5>{value.short_name}</h5>
              </div>
              <div className="white-left-inner">
                <h5>{value.name}</h5>
              </div>
            </div>
            <div className="white-bottom d-flex justify-content-between">
              <div className="white-left-inner mr-2">
                <h5>{value.whitelistAddress}</h5>
              </div>
              <div className="white-right-inner">
                <a
                  href="javascript:void(0)"
                  className="btn btn-danger"
                  onClick={() => classThis.DeleteWalletAddress(value.id)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        );
      });

      return html;
    }
  };

  DeleteWalletAddress = (getAddressId) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.Delete_wallet_address(getAddressId),
        },
        {
          label: "No",
          onClick: () => this.checkCall,
        },
      ],
    });
  };
  Delete_wallet_address = async (api_id) => {
    const config = {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    };
    let Payment_delete_Data = {
      address_id: api_id,
      userId: localStorage.getItem("id"),
    };

    let result = await deleteWhiteList(Payment_delete_Data);
    console.log("resultinsidedelte", result);
    if (result.data.status == true) {
      toast.dismiss();
      toast.success(result.data.message);
      this.ShowListData();
    }
  };
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    if (window.location.href === "http://172.105.119.53/kyc") {
      this.setState({ modalIsOpen: false });
    } else {
      this.setState({ modalIsOpen: true });
    }
  }
  GetUserData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      console.log("kycverification", resp.data[0]);
      if (resp.data[0]) {
        this.setState({
          kycStatus: resp.data[0].kycVerification_status,
          // addon from here
        });

        if (resp.data[0].kycVerification_status === "Y") {
          this.setState({ modalIsOpen: false });
        }
        if (resp.data[0].kycVerification_status === "N") {
          this.setState({ modalIsOpen: true });
        }
        if (resp.data[0].kycVerification_status === null) {
          this.setState({ modalIsOpen: true });
        }
      }
    });
  };

  render() {
    const { t } = this.props;
    var showStatus = "";

    if (this.state.Whitelist_Status == 0) {
      showStatus = "Enable";
    } else if (this.state.Whitelist_Status == 1) {
      showStatus = "Disable";
    }

    return (
      <div className="">
        <DocumentMeta {...meta}>
          <Modal show={this.state.isModalOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Address in Whitelist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="p-3">
                <div className="row mb-3">
                  <div className="col-sm-6">{t("Cryptocoin Name")}:</div>
                  <div className="col-sm-6">{this.CryptoCoinDataHtml()}</div>
                  <p className="errMsg text-center" style={{ color: "red" }}>
                    {t(this.state.coinTypeError)}
                  </p>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-6">{t("Name")} :</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      placeholder="Enter Name"
                      minLength="2"
                      maxLength="30"
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                      value={this.state.name}
                      // onBlur={this.onBlurName}
                    />
                    <p className="errMsg text-center" style={{ color: "red" }}>
                      {this.state.nameError}
                    </p>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-6">Wallet Address :</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      placeholder="Wallet Address"
                      type="text"
                      minLength="24"
                      maxLength="31"
                      name="whitelistAddress"
                      onChange={this.handleChange}
                      value={this.state.whitelistAddress}
                      // onBlur={this.onBlurWhitelistAddress}
                    />
                    <p className="errMsg text-center" style={{ color: "red" }}>
                      {t(this.state.walletError)}
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className=" btn btn_man"
                onClick={this.handleSubmit}
                disabled={this.state.BtnDisable}
              >
                Add{" "}
              </Button>
              <Button variant="secondary" onClick={this.closeModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="bghwhite new_inpage">
            <div className="container container2">
              <LeftTab />
              <div className="in_page">
                <h5>{t("Whitelist")}</h5>
                <div className="wishlist-main-outer">
                  <div className="row">
                    <div className="col-md-4 mb-4">
                      <div className="wishlist-left">
                        {" "}
                        <p>
                          {t("Whitelist is an additional security feature designed to protect your funds. When you enable Whitelist, your Account will be able to withdraw only to whitelisted withdrawal wallet addresses.")}
                        </p>
                        <div className="wishlist-form">
                          <div className="form-group">
                            <lable className="mb-2 display_inline_blog">
                              {t("Cryptocoin Name")}
                            </lable>
                            {this.CryptoCoinDataHtml()}
                            <p className="errMsg mt-0" style={{ color: "red" }}>
                              {t(this.state.coinTypeError)}
                            </p>{" "}
                          </div>

                          <div className="form-group">
                            <lable className="mb-2 display_inline_blog">
                              {t("Name")}
                            </lable>
                            <input
                              className="form-control"
                              placeholder={t("Enter") +" "+t("Name")}
                              type="text"
                              name="name"
                              onChange={this.handleChange}
                              value={this.state.name}
                              // onBlur={this.onBlurName}
                            />
                            <p className="errMsg mt-0" style={{ color: "red" }}>
                              {t(this.state.nameError)}
                            </p>
                          </div>
                          <div className="form-group">
                            <lable className="mb-2 display_inline_blog">
                              {t("Wallet Address")}
                            </lable>
                            <input
                              className="form-control"
                              placeholder={t("Enter") +" "+t("Wallet Address")}
                              type="text"
                              minLength="42"
                              maxLength="42"
                              onKeyPress={(event) => {
                                if (!/[a-zA-Z0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              name="whitelistAddress"
                              onChange={this.handleChange}
                              value={this.state.whitelistAddress}
                              // onBlur={this.onBlurWhitelistAddress}
                            />
                            <p className="errMsg mt-0" style={{ color: "red" }}>
                              {t(this.state.walletError)}
                            </p>
                          </div>
                          <div>
                            <Button
                              className="btn btn_man"
                              onClick={this.handleSubmit}
                              disabled={this.state.BtnDisable}
                            >
                              {t("Add")}{" "}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="whitelist_scrool whilist-right-main">
                        {this.whitelistDataHtml()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Modal1
          id={"add-user-modal"}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
        >
          <img src="assets/img/v_kyc.png" />
          <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
            Your KYC is not done. Kindly complete your KYC.{" "}
          </h2>

          <div>
            <Link className="btn btn_man mt-4 w100px" to="/kyc">
              Okay!
            </Link>
          </div>
        </Modal1> */}
        </DocumentMeta>
      </div>
    );
  }
}

export default withTranslation()(WhiteList);
