import React from "react";

import Footer from "../Common/Footer";
import { Link, withRouter } from "react-router-dom";
import { Component } from "react";
import { withTranslation } from 'react-i18next';
import * as myConstList from "../Common/BaseUrl";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftTab from "../Common/LeftTab";

const baseUrl = myConstList.baseUrl;

const SignupSchema = Yup.object().shape({
  //OldPassword: Yup.string().required("This field is required"),
  // .matches(/[^\s*].*[^\s*]/g, '* Old Password field cannot contain only blankspaces'),
  NewPassword: Yup.string()
    .required("This field is required")
     .matches(
       "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      "Please enter valid password"
   )
    .matches(
      /[^\s*].*[^\s*]/g,
      "New password field cannot contain only blankspaces"
    ),
  CPassword: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("NewPassword"), null], "Password is mis-matched"),
});

export class SecurityContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      authentication_with: null,
      type1: "password",
      type2: "password",
      type3: "password",
    };

    this.SwitchChange = this.SwitchChange.bind(this);

    this.getUserDetails();
    this.GoogleAuthStatus();
    this.LoginActivity();
  }

  componentDidMount() {
    this.SwitchApi();
  }

  getUserDetails = () => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      // this.setState({Whitelist_Status: resp.data[0].whitelist_status })

      this.setState({
        authentication_with: resp.data[0].authentication_with,
        TwoFA: resp.data[0].authentication_with,
      });
    });
  };

  GoogleAuthStatus = () => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios.post(baseUrl + "/frontapi/G_auth_status", {}, config).then((resp) => {
      var resp = resp.data;
      // this.setState({Whitelist_Status: resp.data[0].whitelist_status })
      this.setState({ g_auth_status: resp.Data[0].google_auth_verify });
    });
  };

  SwitchApi() {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(baseUrl + "/frontapi/enable2faStatus", {}, config)
      .then((resp) => {
        var resp1 = resp.data;
        this.setState({ StateValue: resp1.Data[0].enable_2fa });

        if (resp1.Data[0].enable_2fa === "N") {
          this.setState({ checked: false });

          return false;
        } else {
          this.setState({ checked: true });
        }
      });
  }

  SwitchChange(checked) {
    if (
      this.state.StateValue == "N" &&
      this.state.authentication_with === null
    ) {
      toast.dismiss();
      toast.error("Please Select 2FA Option First.");
      return false;
    }

    this.setState({ checked });
    var ToogleValue = "";

    if (this.state.StateValue === "Y") {
      ToogleValue = "N";
    } else {
      ToogleValue = "Y";
    }

    if (this.state.authentication_with !== null || ToogleValue === "N") {
      const config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      let LoginData = {
        enableValue: ToogleValue,
      };

      axios
        .post(baseUrl + "/frontapi/Update2faStatus", LoginData, config)
        .then((resp) => {
          var resp1 = resp.data;
        //  console.log("Statussssssssssss ", resp1);

          if (resp1.status === true) {
            return false;
          }
        });
    } else {
      toast.dismiss();
      toast.error("Verify Google Authentication !");
    }
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, errMsg: "" });
  };

  handleOnBlurOldPassword(event) {
    var password = event.target.value;
    if (!password) {
      toast.dismiss();
      toast.error("Old Password required");
    }
  }

  handleOnBlurNewPassword(event) {
    var Npassword = event.target.value;
    if (!Npassword) {
      toast.dismiss();
      toast.error("New Password required");
    }
    if (Npassword.length < 8) {
      toast.dismiss();
      toast.error("Enter Minimum 8 Character");
      return false;
    }
  }

  handleOnBlurCPassword(event) {
    var CPassword = event.target.value;
    if (!CPassword) {
      toast.dismiss();
      toast.error("Re-enter Your New Password");
      return false;
    }
    if (CPassword.length < 8) {
      toast.dismiss();
      toast.error("Enter Minimum 8 Character");
      return false;
    }
  }

  // handleSubmit = (event) => {
  //   event.preventDefault();

  //   const config = {
  //     headers: {
  //       Authorization: localStorage.getItem("token"),
  //     },
  //   };
  //   let LoginData = {
  //     NewPassword: this.state.NewPassword,
  //     OldPassword: this.state.OldPassword,
  //     CPassword: this.state.CPassword,
  //   };

  //   axios
  //     .post(baseUrl + "/frontapi/changePassword", LoginData, config)
  //     .then((resp) => {
  //       var response = resp.data;

  //       this.setState({ NewPassword: "", OldPassword: "", CPassword: "" });
  //       if (response.status === true) {
  //         toast.dismiss();
  //         toast.success(response.messagecu);
  //         return false;
  //       } else {
  //         toast.dismiss();
  //         toast.error(response.messagecu);
  //         return false;
  //       }
  //     });
  // };
  Two_FASubmit = (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    let LoginData = {
      TwoFA: this.state.TwoFA,
    };

    if (
      this.state.TwoFA == undefined ||
      this.state.TwoFA === "" ||
      this.state.TwoFA === null
    ) {
      toast.dismiss();
      toast.error("Please Setup 2FA");
      return false;
    }
    axios.post(baseUrl + "/frontapi/twoFA", LoginData, config).then((resp) => {
      var resp1 = resp.data;

      if (resp1.status === true) {
        this.getUserDetails();
        toast.dismiss();
        toast.success(" Authentication details updated successfully");
        return false;
      }
    });
  };

  LoginActivity = () => {
    const config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    if (!config) {
      window.location.href = "/Login";
    }

    axios.post(baseUrl + "/frontapi/login-list", {}, config).then((resp) => {
      var respNew = resp.data;
     // console.log("loginList----->", respNew.findData);
      if (respNew.status === true) {
        this.setState({
          tabledata: respNew.findData,
          respStatus: respNew.status,
        });
      }
    });
  };
  handleClickOld = () => {
    this.setState(({ type1 }) => ({
      type1: type1 === "text" ? "password" : "text",
    }));
   // console.log("handleclick", this.state.type);
  };

  handleClickNew = () => {
    this.setState(({ type2 }) => ({
      type2: type2 === "text" ? "password" : "text",
    }));
   // console.log("handleclick", this.state.type);
  };
  handleClickConfirm = () => {
    this.setState(({ type3 }) => ({
      type3: type3 === "text" ? "password" : "text",
    }));
    //console.log("handleclick", this.state.type);
  };
  showTableHtml = () => {
    if (this.state.respStatus === true) {
      const classThis = this;
      const html = [];
      var snno = 0;
      const sorted = this.state.tabledata.sort((a, b) => {
        const adate = a.createdAt.split(",");
        const bdate = b.createdAt.split(",");
      //  console.log("adate", adate, bdate);
        const aDate = new Date(adate[0] + " " + adate[1]);
        const bDate = new Date(bdate[0] + " " + bdate[1]);
        return new Date(b.createdAt) - new Date(a.createdAt);

        // return bDate.getTime() - aDate.getTime()
      });
     // console.log("adateadateadate0", sorted);

      sorted.map(function (value, i) {
        snno++;
        html.push(
          <tr>
            <td> {snno} </td>
            <td> {moment.utc(value.createdAt).local().format("lll")} </td>
            <td>{value.event}</td>
            <td>{value.device}</td>
            <td>{value.browser}</td>
            <td>{value.osName}</td>
            <td>{value.ip}</td>
            <td>
              {value.cityName}, {value.countryName}
            </td>
          </tr>
        );
      });
      return html;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Formik
          initialValues={{
            OldPassword: "",
            NewPassword: "",
            CPassword: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const config = {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            };
            axios
              .post(baseUrl + "/frontapi/changePassword", values, config)
              .then((resp) => {
                var response = resp.data;
                //console.log("changepassword", response);

                if (response.status === true) {
                  localStorage.clear();

                  toast.dismiss();
                  toast.success(response.message);
                  this.setState({
                    NewPassword: "",
                    OldPassword: "",
                    CPassword: "",
                  });
                  setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/login-to-buy-sell-crypto";
                  }, 2000);
                  return false;
                } else {
                  toast.dismiss();
                  toast.error(response.message);
                  return false;
                }
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <div className="col-lg-3 mb-3">
                <div className="white_box h_100 mb-0">
                  <h5>{t("Change Password")}</h5>
                  <div className="bank-info info-main-outer mt-2">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {/* <div className="col-md-12">
                          <div className="form-group eye_pass eye_pass_two">
                            <label>{t("Old Password")}</label>
                            <input
                              className="form-control"
                              type={this.state.type1}
                              name="OldPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={t("Enter")+" "+t("Old Password")}
                            />
                            <span
                              className="password__show eye1"
                              onClick={this.handleClickOld}
                            >
                              {this.state.type1 === "text" ? (
                                <i className="las la-eye"></i>
                              ) : (
                                <i className="las la-low-vision"></i>
                              )}
                            </span>
                            {errors.OldPassword && touched.OldPassword && (
                              <div style={{ color: "red" }}>
                                {t(errors.OldPassword)}
                              </div>
                            )}
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <div className="form-group eye_pass eye_pass_two">
                            <label>{t("New Password")}</label> 
                            <input
                              type={this.state.type2}
                              className="form-control"
                              name="NewPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={t("enter")+" "+t("New Password")}
                              
                            />
                            <span
                              className="password__show eye1"
                              onClick={this.handleClickNew}
                            >
                              {this.state.type2 === "text" ? (
                                <i className="las la-eye"></i>
                              ) : (
                                <i className="las la-low-vision"></i>
                              )}
                            </span>
                            
                            {errors.NewPassword && touched.NewPassword && (
                              <div style={{ color: "red" }}>
                                {t(errors.NewPassword)}
                              </div>
                            )}
                            <span>{t("Password must 8 characters and contain at least one upper case letter, one lower case letter, one number, and one special character")}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group eye_pass eye_pass_two">
                            <label>{t("Confirm New Password")}</label>
                            <input
                              type={this.state.type3}
                              className="form-control"
                              name="CPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={t("enter")+" "+t("Confirm New Password")}
                            />
                            <span
                              className="password__show eye1"
                              onClick={this.handleClickConfirm}
                            >
                              {this.state.type3 === "text" ? (
                                <i className="las la-eye"></i>
                              ) : (
                                <i className="las la-low-vision"></i>
                              )}
                            </span>
                            {errors.CPassword && touched.CPassword && (
                              <div style={{ color: "red" }}>
                                {t(errors.CPassword)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          <button
                            className="btn btn_man fs14"
                            // disabled={
                            //   !btnDisableOP ||
                            //   !btnDisableNP ||
                            //   !btnDisableCNP
                            // }
                          >
                            {t("Change Password")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </>
    );
  }
}

export default withRouter(withTranslation()(SecurityContent));
